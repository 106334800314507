import { createElement, isValidElement } from 'react'
import type { ComponentType, PropsWithChildren } from 'react'
import type { RouteProps } from 'react-router-dom'
import { Redirect, Route } from 'react-router-dom'

import * as ErrorScreens from 'screens/ErrorScreens'
import { SHIPPER_HOME, SHIPPER_LOGIN } from 'utils/constants'
import user from 'utils/user'

interface Props extends RouteProps {
  component?: ComponentType<PropsWithChildren<unknown>>
  enabled?: boolean
}

const Login = ({ path }: { path: string }) => {
  return <Redirect to={`${SHIPPER_LOGIN}?next=${path || SHIPPER_HOME}`} />
}

export default function PrivateRoute({
  component,
  enabled,
  render,
  children,
  ...rest
}: Props) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user.isLoggedIn) {
          return <Login path={props.location.pathname} />
        }

        if (enabled === false) {
          return <ErrorScreens.NotFound />
        }

        if (component) {
          return createElement(component)
        }

        if (render) {
          return render(props)
        }

        if (isValidElement(children)) {
          return children
        }
        return <ErrorScreens.NotFound />
      }}
    />
  )
}

import type { AxiosRequestConfig } from 'axios'

import type {
  RawContact,
  ChatContactResponse,
  ChatContactInfoResponse,
  CreateChatContactRequestBody,
  CreateConversationRequestBody,
  ConversationResponse,
  UpdateConversationRequestBody,
} from '../types'

import httpClient from './httpClient'

export const getChatsList = async () => {
  const { data } = await httpClient.get('/conversations')
  return data
}

export const createChatContact = async (
  body: CreateChatContactRequestBody
): Promise<ChatContactResponse> => {
  const { data } = await httpClient.put('/contacts', body)
  return data
}

export const getContactsList = async () => {
  const { data } = await httpClient.get<RawContact[]>('/contacts')
  return data
}

export const createConversation = async (
  body: CreateConversationRequestBody
): Promise<ConversationResponse> => {
  const { data } = await httpClient.put('/conversations', body)
  return data
}

export const updateConversation = async (
  body: UpdateConversationRequestBody
): Promise<ConversationResponse> => {
  const { data } = await httpClient.patch('/conversations', body)
  return data
}

export const getUserContactInfo = async (
  config?: AxiosRequestConfig
): Promise<ChatContactInfoResponse> => {
  const { data } = await httpClient.get('/accounts/me', config)
  return data
}

import { IconAttention } from '@loadsmart/icons'
import type { TextFieldProps as MTextFieldProps } from '@loadsmart/loadsmart-ui'
import { TextField as MTextField } from '@loadsmart/loadsmart-ui'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'

import { BaseField } from './BaseField'
import type { CustomFormikFieldProps } from './CustomFormikField'
import { CustomFormikField } from './CustomFormikField'

export interface TextFieldProps
  extends Omit<MTextFieldProps, 'name' | 'value' | 'form' | 'className'>,
    Omit<CustomFormikFieldProps, 'children'> {
  tip?: string
}

export function TextField({ ...props }: TextFieldProps) {
  return (
    <CustomFormikField {...props}>
      {({
        field: { onChange: fieldOnChange, ...field },
        baseFieldProps,
        baseProps,
        form: { setFieldTouched },
      }) => {
        const mappedProps = {
          status:
            props.status ??
            ((baseFieldProps.error
              ? 'danger'
              : undefined) as MTextFieldProps['status']),
          trailing:
            props.trailing ??
            (baseFieldProps.error ? (
              <IconAttention width={12} height={12} />
            ) : undefined),
          onChange: (event: EventLike<string>) => {
            fieldOnChange(event)
            setFieldTouched(field.name, true, false)
          },
          ...field,
          ...baseProps,
        }

        return (
          <BaseField {...baseFieldProps} tip={props.tip}>
            <MTextField {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}

import { formatDistance } from 'date-fns'
import moment from 'moment-business-days'
import type { Moment, MomentInput, unitOfTime } from 'moment-business-days'
import 'moment-precise-range-plugin'
import momentTimezone from 'moment-timezone'

import { plural } from './strings'

export function formatDate(
  dateString: string,
  dateFormat = 'MM/DD/YY'
): string {
  const date = moment(dateString)
  if (!date.isValid()) {
    throw Error('Invalid date format.')
  }
  return date.format(dateFormat)
}

export function formatDateUTC(date: string, dateFormat = 'MM/DD/YY') {
  return moment.utc(date).format(dateFormat)
}

export function formatDateTime(dateString: string): string {
  const date = moment(dateString)
  if (!date.isValid()) {
    throw Error('Invalid date format.')
  }
  return date.format('MM/DD/YY, h:mm A')
}

export function formatDateTimeWithTimezone(
  dateString: string,
  dateTimeFormat = 'MM/DD/YY, h:mm A',
  timezone = 'America/New_York'
): string {
  const date = momentTimezone.tz(dateString, timezone)
  if (!date.isValid()) {
    throw Error('Invalid date format.')
  }
  return date.format(dateTimeFormat)
}

export function getCurrentTimezone() {
  return momentTimezone.tz.guess()
}

/**
 * Returns the timezone abbreviation for the given timezone.
 * America/New_York => EDT
 *
 * @param timezone The timezone to format. If not provided the function
 * returns an empty string
 * @returns The timezone abbreviation.
 */
export function formatTimezoneAbbreviation(timezone?: string | null) {
  if (!timezone) {
    return ''
  }

  return momentTimezone.tz(timezone).zoneAbbr()
}

/**
 * Returns the timezone abbreviation for the current timezone.
 *
 * @returns The timezone abbreviation.
 */
export function formatCurrentTimezoneAbbreviation() {
  return formatTimezoneAbbreviation(getCurrentTimezone())
}

export function isBusinessDayOver(date?: MomentInput) {
  return !moment(date).isBusinessDay() || moment(date).hour() >= 17
}

export function asISODate(date: Moment) {
  return date.format('YYYY-MM-DD')
}

const DATE_FORMAT = 'MM/DD/YYYY'
const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm'
const DATE_TIME_EXACT_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ'

export function formatISODate(dateString: string): string {
  return asISODate(
    moment(
      dateString,
      [DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_EXACT_FORMAT],
      true
    )
  )
}

export function formatRFPDate(dateString: string): string {
  const date = moment(dateString)
  if (!date.isValid()) {
    throw Error('Invalid date format.')
  }
  return date.format('MMM D, YYYY')
}

export function formatRFPDateTime(dateString: string): string {
  const date = moment(dateString)
  if (!date.isValid()) {
    throw Error('Invalid date format.')
  }
  return date.format('MMM D, YYYY @ h:mm A')
}

export function setMomentTime(value: Moment | string | null, time: string) {
  const [hour, minute] = [...time.split(':').map(Number), 0]
  return moment(value || undefined)
    .set({ hour, minute })
    .startOf('minute')
}

export function humanizeDuration(
  from: MomentInput,
  to: MomentInput,
  depth = Infinity
) {
  const mFrom = moment(from)
  const mTo = moment(to)
  if (!mFrom.isValid()) {
    throw Error('Invalid date format.')
  }
  if (!mTo.isValid()) {
    throw Error('Invalid date format.')
  }
  const { firstDateWasLater: _, ...diff } = moment.preciseDiff(mFrom, mTo, true)

  const dateDiff: { [key: string]: number } = diff

  const periods: string[] = []

  for (const [period, value] of Object.entries<number>(dateDiff)) {
    if (periods.length < depth && value > 0) {
      periods.push(period)
    }
  }

  return periods.reduce((acc, period, index) => {
    const value = dateDiff[period]

    const pluralPeriod = plural(period.slice(0, period.length - 1), value)
    const includeAnd =
      periods.length > 1 && index === periods.length - 1 ? ' and' : ''

    return `${acc}${includeAnd} ${value} ${pluralPeriod}`.trim()
  }, '')
}

export function setTimeToDate(dateString: string, time: string) {
  const date = setMomentTime(dateString, time)
  return date.toISOString()
}

export function getTimeDiff(dateTime: string) {
  const now = momentTimezone()
  const comparisonDate = momentTimezone(dateTime)

  return comparisonDate.diff(now, 'hours', true)
}

export function getDatesDiff(
  from: string | Date,
  to: string | Date,
  unit: unitOfTime.Diff = 'ms'
) {
  const momentFrom = momentTimezone(from)
  const momentTo = momentTimezone(to)

  return momentTo.diff(momentFrom, unit)
}

export function getPreciseDiff(
  from?: string | number | null,
  to = new Date().toISOString()
) {
  const momentFrom = moment(from)
  const momentTo = moment(to)
  if (!momentFrom.isValid() || !momentTo.isValid()) {
    return null
  }
  return moment.preciseDiff(momentFrom, momentTo, true)
}

export function getDeadlineProgressPercentage({
  deadlineEnd,
  deadlineStart,
}: {
  deadlineEnd?: string | null
  deadlineStart?: string | null
}) {
  if (!deadlineEnd || !deadlineStart) {
    return 0
  }

  const total = getDatesDiff(deadlineStart, deadlineEnd)
  const timeDiff = getDatesDiff(new Date().toISOString(), deadlineEnd)

  if (timeDiff <= 0) {
    return 0
  }

  return Math.round((timeDiff * 100) / total)
}

export function addMinutes(currentDate: Date, minutesToBeAdded: number) {
  return moment(currentDate).add(minutesToBeAdded, 'minutes')
}

export function addDays(currentDate: Date, daysToBeAdded: number) {
  return moment(currentDate).add(daysToBeAdded, 'days')
}

export function addHours(currentDate: Date, hoursToBeAdded: number) {
  return moment(currentDate).add(hoursToBeAdded, 'hours')
}

export function isSameDay(date1: string, date2: string) {
  return moment(date1).utc().dayOfYear() === moment(date2).utc().dayOfYear()
}

export const formatDateDistance = (
  dateFrom: Date,
  dateTo: Date,
  options?: { addSuffix?: boolean }
) => {
  return formatDistance(dateFrom, dateTo, options)
}

export function formatTimeDifference(
  from: string,
  to = new Date().toISOString()
) {
  const dateDiff = getPreciseDiff(from, to)

  if (!dateDiff) {
    return ''
  }

  if (dateDiff.years) {
    return `${dateDiff.years.toString().padStart(2, '0')}yr`
  }

  if (dateDiff.months) {
    return `${dateDiff.months.toString().padStart(2, '0')}mo`
  }

  if (dateDiff.days) {
    return `${dateDiff.days.toString().padStart(2, '0')}d`
  }

  if (dateDiff.hours) {
    return `${dateDiff.hours.toString().padStart(2, '0')}h`
  }

  if (dateDiff.minutes) {
    return `${dateDiff.minutes.toString().padStart(2, '0')}m`
  }

  return ''
}

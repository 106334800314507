import { IconCheck, IconCompany } from '@loadsmart/icons'
import { Button } from '@loadsmart/loadsmart-ui'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import styled from 'styled-components'

import type { Item } from './types'

const StyledButton = styled(Button)`
  justify-content: flex-start;
  text-transform: none;
  width: 100%;

  span:nth-child(2) {
    margin-right: auto;
  }
`
interface Props {
  item: Item
  onClick: (item: Item) => void
  isSelected: boolean
}

const ListItem = ({ item, onClick, isSelected }: Props) => {
  return (
    <StyledButton
      leading={
        <IconCompany
          width={16}
          height={16}
          fill={toCSSValue('color-primary-60')}
        />
      }
      type="button"
      variant="tertiary"
      onClick={() => onClick(item)}
      trailing={
        isSelected ? (
          <IconCheck
            width={16}
            height={16}
            fill={toCSSValue('color-primary-60')}
          />
        ) : null
      }
    >
      {item.label}
    </StyledButton>
  )
}

export default ListItem

import { SideNavigation, TopNavigation } from '@loadsmart/loadsmart-ui'
import { getToken as token } from '@loadsmart/loadsmart-ui/dist/theming'
import styled from 'styled-components'

import conditional, { whenProps } from 'utils/conditional'
import transition from 'utils/transition'

export const StyledTopNavigationWrapper = styled.div`
  width: 100%;
  ${TopNavigation.OpenSideNavButton} {
    display: none;
  }

  ${TopNavigation.Logo} {
    display: none;
  }

  @media (max-width: ${token('side-navigation-collapsing-breakpoint')}) {
    ${TopNavigation.OpenSideNavButton} {
      display: block;
    }

    ${TopNavigation.Logo} {
      display: block;
    }
  }
`

export const StyledSideNavigationWrapper = styled.div<{ $open: boolean }>`
  left: 0;
  position: static;
  top: 0;

  transform: ${conditional({
    'translateX(-100%)': whenProps({
      $open: false,
    }),
    'translateX(0px)': whenProps({
      $open: true,
    }),
  })};

  ${transition({
    property: 'transform',
    duration: '200ms',
  })}

  z-index: ${token('z-index-overlay')};

  ${SideNavigation.CloseButton} {
    display: none;
    margin-left: ${token('space-l')};

    svg {
      fill: ${token('color-neutral-white')};
    }
  }

  @media (max-width: ${token('side-navigation-collapsing-breakpoint')}) {
    position: fixed;

    aside {
      flex: 0 0 ${token('side-navigation-width-large')};
      width: ${token('side-navigation-width-large')};
    }

    ${SideNavigation.Logo} {
      display: none;
    }

    ${SideNavigation.CloseButton} {
      display: block;
    }
  }
`

import { IconAttention } from '@loadsmart/icons'
import type {
  LabelProps,
  TextareaProps as MTextareaProps,
} from '@loadsmart/loadsmart-ui'
import { Textarea as MTextarea } from '@loadsmart/loadsmart-ui'
import type { TextFieldProps as MTextFieldProps } from '@loadsmart/loadsmart-ui/dist/components/TextField/TextField'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'

import { BaseField } from './BaseField'
import { CustomFormikField } from './CustomFormikField'
import type { CustomFormikFieldProps } from './CustomFormikField'

export interface TextareaProps
  extends Omit<MTextareaProps, 'name' | 'value' | 'form'>,
    Omit<CustomFormikFieldProps, 'children'> {
  label: string
  labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  name: string
}

export function Textarea({ ...props }: TextareaProps) {
  return (
    <CustomFormikField {...props}>
      {({
        field: { onChange: fieldOnChange, ...field },
        baseFieldProps,
        baseProps,
        form: { setFieldTouched },
      }) => {
        const mappedProps = {
          ...baseProps,
          status:
            props.status ??
            ((baseFieldProps.error
              ? 'danger'
              : undefined) as MTextFieldProps['status']),
          trailing:
            props.trailing ??
            (baseFieldProps.error ? (
              <IconAttention width={12} height={12} />
            ) : undefined),
          onChange: (event: EventLike<string>) => {
            fieldOnChange(event)
            setFieldTouched(field.name, true, false)
          },
          ...field,
          ...props,
        }

        return (
          <BaseField {...baseFieldProps}>
            <MTextarea {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}

export function assertHasDotAtEnd(sentence: string) {
  if (sentence.endsWith('.')) {
    return sentence
  }
  return sentence + '.'
}

export function truncateFileName(fileName: string, maxLength = 40) {
  if (fileName.length < maxLength) {
    return fileName
  }
  if (maxLength < 25) {
    return fileName.substring(0, maxLength) + '…'
  }
  return `${fileName.slice(0, maxLength - 9)}…${fileName.slice(
    fileName.length - 8
  )}`
}

export function capitalize(s: string) {
  return s ? `${s.charAt(0).toUpperCase()}${s.slice(1)}` : ''
}

export function plural(word: string, value: number, pluralForm?: string) {
  if (!pluralForm) {
    return `${word}${value !== 1 ? 's' : ''}`
  }

  return value !== 1 ? pluralForm : word
}

export const nameValidationRegex = /^(([\w-.]+)[ ]*(.+))[ ]*\b(\w+)\s*$/

export function splitFullName(fullName: string): {
  firstName: string
  lastName: string
} {
  const nameParts = fullName.trim().split(/\s+/)

  if (nameParts.length === 1) {
    return { firstName: nameParts[0], lastName: '' }
  }

  const lastName = nameParts.pop() as string
  const firstName = nameParts.join(' ')

  return { firstName, lastName }
}

export function toTitleCase(phrase: string) {
  return phrase
    ?.toLowerCase()
    .split('_')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function toTitleCaseByWord(phrase: string) {
  return phrase
    ?.toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function formatDaysAndHours(time: string) {
  const [day, hour] = time
    .split(' ')
    .map((unit: string) => parseInt(unit.slice(0, -1), 10))
  if (day && hour) {
    return `${day}d ${hour}h`
  }
  if (day) {
    return `${day}d`
  }
  if (hour) {
    return `${hour}h`
  }
}

export function convertNumericToString(value?: string | number | null) {
  if (typeof value === 'number' && !isNaN(value)) {
    return value.toString()
  }

  return value
}

export function toUpperCase<T extends string>(str: T) {
  return str.toUpperCase() as Uppercase<T>
}

export function joinValidStrings(
  values: Array<string | null | undefined>,
  separator = ' '
) {
  return values.filter(Boolean).join(separator)
}

export function slugify(string: string) {
  return string
    .toLowerCase()
    .replace(/^(?:\s+|\s+)$/g, '')
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

import { Drawer } from '@loadsmart/miranda-react'
import { useEffect, useState } from 'react'

import type { FacilityDetailsV2 } from 'services/facilities'

import { FacilityDetails } from './FacilityDetails'
import { FacilityEditForm } from './FacilityEditForm'

export type FacilityManagerDrawerProps = {
  isOpen: boolean
  onClose: () => void
  onClosed: () => void
  facilityUUID: string
  initialView?: 'details' | 'edit-form'
  onFacilitySaved?: (facility: FacilityDetailsV2) => void
}

export function FacilityManagerDrawer({
  isOpen,
  onClose,
  onClosed,
  facilityUUID,
  initialView = 'edit-form',
  onFacilitySaved,
}: FacilityManagerDrawerProps) {
  const [currentView, setCurrentView] = useState(initialView)

  useEffect(() => {
    if (isOpen) {
      setCurrentView(initialView)
    }
  }, [initialView, isOpen])

  return (
    <Drawer
      open={isOpen}
      size="large"
      onClose={onClose}
      onClosed={onClosed}
      divided
      data-testid="facility-drawer-v2"
    >
      {currentView === 'details' ? (
        <FacilityDetails
          facilityUUID={facilityUUID}
          onEditFacility={() => {
            setCurrentView('edit-form')
          }}
        />
      ) : (
        <FacilityEditForm
          facilityUUID={facilityUUID}
          onClose={onClose}
          onFacilitySaved={onFacilitySaved}
        />
      )}
    </Drawer>
  )
}

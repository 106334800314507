import { IconSearch } from '@loadsmart/icons'
import { Dialog, Layout, TextField } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import ListItem from './ListItem'
import type { Item } from './types'

const ListWrapper = styled(Layout.Stack)`
  max-height: 400px;
  overflow-y: auto;
`

interface Props {
  title: string
  placeholder?: string
  isOpen: boolean
  list: Array<Item>
  defaultSelectedItem: Item
  onClose: () => void
  onConfirm: (item: Item) => void
}

const SearchDialog = ({
  title,
  placeholder = 'Search',
  isOpen,
  list,
  defaultSelectedItem,
  onClose,
  onConfirm,
}: Props) => {
  const [filteredList, setFilteredList] = useState(list)
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    if (searchQuery) {
      setFilteredList(
        list.filter((item) => {
          const formattedLabel = item.label.toLowerCase()
          return formattedLabel.startsWith(searchQuery.toLowerCase())
        })
      )
    } else {
      setFilteredList(list)
    }
  }, [list, searchQuery])

  return (
    <Dialog open={isOpen} size="medium" onClose={onClose}>
      <Dialog.Header>
        {title}
        <Dialog.Close />
      </Dialog.Header>
      <Dialog.Body>
        <Layout.Stack gap="spacing-6">
          <TextField
            id="search-items"
            aria-label="search items field"
            value={searchQuery}
            onInput={(event) =>
              setSearchQuery((event.target as HTMLInputElement).value)
            }
            placeholder={placeholder}
            leading={
              <IconSearch
                width={16}
                height={16}
                fill={toCSSValue('color-text-placeholder')}
              />
            }
          />
          <ListWrapper gap="none" justify="space-between">
            {filteredList.map((item) => (
              <Layout.Box key={item.id} padding="spacing-1">
                <ListItem
                  item={item}
                  onClick={setSelectedItem}
                  isSelected={item.value === selectedItem.value}
                />
              </Layout.Box>
            ))}
          </ListWrapper>
        </Layout.Stack>
      </Dialog.Body>
      <Dialog.Footer />
      <Dialog.Actions>
        <Dialog.ActionTertiary onClick={onClose}>Cancel</Dialog.ActionTertiary>
        <Dialog.ActionPrimary
          disabled={defaultSelectedItem.id === selectedItem.id}
          onClick={() => {
            onConfirm(selectedItem)
            onClose()
          }}
        >
          Confirm
        </Dialog.ActionPrimary>
      </Dialog.Actions>
    </Dialog>
  )
}

export default SearchDialog

import { IconPaper, IconClose } from '@loadsmart/icons'
import { Spinner, Text } from '@loadsmart/loadsmart-ui'
import { useRef, useCallback, useState, useMemo } from 'react'
import type {
  ChangeEvent,
  DragEvent,
  FunctionComponent,
  HTMLAttributes,
  MouseEvent,
  KeyboardEvent,
  PropsWithChildren,
} from 'react'
import styled, { css } from 'styled-components'

import { UnstyledButton } from 'components/Button'

const defaultCSS = css`
  border: 8px dashed ${({ theme }) => theme.colors.backgroundLight};
`

const hasErrorCSS = css`
  border: 4px dashed ${({ theme }) => theme.colors.danger};
`

const Input = styled.input`
  display: none;
`

const Div = styled.div<{
  hasFile?: boolean
  hasError?: boolean
  disabled?: boolean
}>`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 100%;
  justify-content: center;
  line-height: 21px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  ${({ disabled }) => disabled && `pointer-events: none;`}
  ${({ hasError }) => {
    if (hasError) {
      return hasErrorCSS
    }
    return defaultCSS
  }}
`

const RemoveButton = styled(UnstyledButton)`
  align-items: center;
  color: ${({ theme }) => theme.colors.danger};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  padding: 0;
`

const FileList = styled.ul`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
  li {
    align-items: center;
    display: inline-flex;
    :not(:last-child) {
      margin-right: 20px;
    }
    *:not(:last-child) {
      margin-right: 10px;
    }
  }
`

const Container = styled.div<{
  fileListAtBottom?: boolean
}>`
  display: flex;
  flex-direction: ${({ fileListAtBottom }) =>
    fileListAtBottom ? 'column-reverse' : 'column'};
  height: 100%;
`

const FileCountMessage = styled.p`
  margin: 5px 0;
  padding: 0;
`

const FileSizeMessage = styled(FileCountMessage)`
  color: ${({ theme }) => theme.colors.neutral};
  font-weight: 600;
`

interface Props extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> {
  value: File[]
  name?: string
  accept?: string[]
  error?: boolean
  isLoading?: boolean
  fileCount?: number
  sizeLimitInMb?: number
  fileListAtBottom?: boolean
  getCustomUploadBody?: FunctionComponent<PropsWithChildren<any>>
  getCustomFileListBody?: FunctionComponent<PropsWithChildren<any>>
  onChange: (file: File[]) => void
}

export default function FileInput({
  onChange,
  value,
  accept,
  error,
  isLoading,
  className,
  fileCount = 1,
  sizeLimitInMb,
  getCustomUploadBody,
  getCustomFileListBody,
  fileListAtBottom,
  ...props
}: Props) {
  const [isDragging, setIsDragging] = useState(false)
  const [inputValue, setInputValue] = useState<string[]>([])

  const fileInput = useRef<HTMLInputElement>(null)

  const onClick = useCallback(() => {
    if (fileInput.current !== null) {
      fileInput.current.value = ''
      fileInput.current.click()
    }
  }, [fileInput])

  const onChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setInputValue([...inputValue, event.currentTarget.value])
      const uploaded = Array.from(event?.currentTarget?.files || [])
      const files = [...value, ...uploaded].slice(0, fileCount)
      onChange(files)
    },
    [onChange, value, fileCount, inputValue]
  )

  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      if (isDragging) {
        setIsDragging(false)
      }
      const uploaded = Array.from(event?.dataTransfer?.files || [])
      const files = [...value, ...uploaded].slice(0, fileCount)
      onChange(files)
    },
    [isDragging, onChange, value, fileCount]
  )

  const onDragStart = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  const onDragOver = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      if (!isDragging) {
        setIsDragging(true)
      }
    },
    [isDragging]
  )

  const onDragLeave = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      if (isDragging) {
        setIsDragging(false)
      }
    },
    [isDragging]
  )

  const removeFile = useCallback(
    (index: number) => (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      event.preventDefault()
      const newFiles = [...value]
      const removed = newFiles.splice(index, 1)
      onChange(newFiles)
      setInputValue(
        inputValue.filter((fileName) => !fileName.endsWith(removed?.[0]?.name))
      )
    },
    [onChange, value, inputValue]
  )

  const onKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return
    }
    fileInput?.current?.click()
  }, [])

  const getDefaultUploadBody = (
    isDraggingFile: boolean,
    totalFileCount: number,
    isUploading?: boolean,
    mbSizeLimit?: number
  ) => {
    if (isUploading) {
      return <Spinner size={80} />
    }
    if (isDraggingFile) {
      return (
        <Text variant="caption" color="color-neutral">
          Release to upload the file
        </Text>
      )
    }
    return (
      <>
        <FileCountMessage>
          Drag and drop or <b>browse</b>{' '}
          {`up to ${totalFileCount} file${totalFileCount > 1 ? 's' : ''}`}
        </FileCountMessage>
        {mbSizeLimit ? (
          <FileSizeMessage>
            The maximum file size is {mbSizeLimit}MB each.
          </FileSizeMessage>
        ) : null}
      </>
    )
  }

  const getDefaultFileListBody = (
    files: File[],
    removeFileCallback: (index: number) => any
  ) => {
    return (
      <FileList>
        {Array.from(files).map((file: File, index: number) => (
          <li key={file.name}>
            <IconPaper height={18} width={18} />
            <span>{file.name}</span>
            <RemoveButton
              onClick={removeFileCallback(index)}
              data-testid={`remove-${file.name}`}
            >
              <IconClose height={10} width={10} />
            </RemoveButton>
          </li>
        ))}
      </FileList>
    )
  }

  const memoizedInput = useMemo(() => {
    return (
      <Input
        {...props}
        onInput={onChangeInput}
        type="file"
        ref={fileInput}
        accept={accept ? accept.join(',') : undefined}
        disabled={isLoading}
        multiple={fileCount > 1}
      />
    )
  }, [accept, fileCount, isLoading, onChangeInput, props])

  const getFileListBody = (getCustomFileListBody || getDefaultFileListBody)(
    value,
    removeFile
  )

  const getUploadBody = (getCustomUploadBody || getDefaultUploadBody)(
    isDragging,
    fileCount,
    isLoading,
    sizeLimitInMb
  )

  return (
    <>
      {memoizedInput}
      <Container fileListAtBottom={fileListAtBottom}>
        {(value?.length || 0) > 0 ? getFileListBody : null}
        <Div
          tabIndex={0}
          role="button"
          aria-pressed="false"
          onClick={onClick}
          onDrop={onDrop}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onKeyPress={onKeyPress}
          hasFile={!!value}
          hasError={error}
          disabled={isLoading || value.length >= fileCount}
          className={className}
        >
          {getUploadBody}
        </Div>
      </Container>
    </>
  )
}

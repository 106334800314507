import type {
  LabelProps,
  ToggleGroupProps as MToggleGroupProps,
} from '@loadsmart/loadsmart-ui'
import { ToggleGroup as MToggleGroup } from '@loadsmart/loadsmart-ui'
import type { Spacing } from '@loadsmart/loadsmart-ui/dist/components/Layout/Layout.types'

import { BaseField } from './BaseField'
import { CustomFormikField } from './CustomFormikField'

export interface ToggleGroupProps
  extends Omit<MToggleGroupProps, 'name' | 'value' | 'form'> {
  label?: string
  labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  name: string
  required?: boolean
  space?: Spacing
}

export function ToggleGroup({ ...props }: ToggleGroupProps) {
  return (
    <CustomFormikField {...props}>
      {({
        field: { onChange: fieldOnChange, ...field },
        baseFieldProps,
        baseProps,
        form: { setFieldTouched },
      }) => {
        const mappedProps = {
          ...baseProps,
          ...field,
          ...props,
          onChange: (
            event: Parameters<NonNullable<ToggleGroupProps['onChange']>>[0]
          ) => {
            fieldOnChange(event)
            setFieldTouched(field.name, true, false)
            props.onChange?.(event)
          },
        }

        return (
          <BaseField {...baseFieldProps}>
            <MToggleGroup {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}

import { SideNavigation, Tag } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'

import { AppRoutes } from 'router/AppRoutes'
import { USER_TYPE } from 'utils/constants'
import userWrapper from 'utils/user'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

export const AppsMenu = () => {
  const history = useHistory()

  if (userWrapper.isUserType([USER_TYPE.SG])) {
    return (
      <SideNavigation.Menu label="Apps">
        <SideNavigation.Menu.Item
          active={isActivePath(AppRoutes.FreightWaves)}
          label="FreightWaves"
          url={AppRoutes.FreightWaves}
          onClick={(event) => {
            event.preventDefault()
            navbarLinkClicked('FreightWaves', AppRoutes.FreightWaves, history)
          }}
          trailing={
            <Tag size="small" variant="success">
              NEW
            </Tag>
          }
        />
      </SideNavigation.Menu>
    )
  }

  return null
}

import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

import plusJakartaSansBold from '../assets/fonts/PlusJakartaSans/PlusJakartaSans-Bold.woff2'
import plusJakartaSansMedium from '../assets/fonts/PlusJakartaSans/PlusJakartaSans-Medium.woff2'
import plusJakartaSansRegular from '../assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.woff2'
import sharpSansBoldFallback from '../assets/fonts/SharpSans/SharpSans-Bold.woff'
import sharpSansBold from '../assets/fonts/SharpSans/SharpSans-Bold.woff2'
import sharpSansFallback from '../assets/fonts/SharpSans/SharpSans-Book.woff'
import sharpSans from '../assets/fonts/SharpSans/SharpSans-Book.woff2'
import sharpSansLightFallback from '../assets/fonts/SharpSans/SharpSans-Light.woff'
import sharpSansLight from '../assets/fonts/SharpSans/SharpSans-Light.woff2'
import sharpSansMediumFallback from '../assets/fonts/SharpSans/SharpSans-Medium.woff'
import sharpSansMedium from '../assets/fonts/SharpSans/SharpSans-Medium.woff2'
import sharpSansSemiBoldFallback from '../assets/fonts/SharpSans/SharpSans-Semibold.woff'
import sharpSansSemiBold from '../assets/fonts/SharpSans/SharpSans-Semibold.woff2'

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  body {
    @font-face {
     font-family: 'Sharp Sans';
     font-style: normal;
     font-weight: 300;
     font-display: swap;
     src: local('Sharp Sans Light'), local('SharpSans-Light'), url(${sharpSansLight}) format('woff2'),
     url(${sharpSansLightFallback}) format('woff');
    };
    @font-face {
     font-family: 'Sharp Sans';
     font-style: normal;
     font-weight: normal;
     font-display: swap;
     src: local('Sharp Sans Book'), local('SharpSans-Book'), url(${sharpSans}) format('woff2'),
     url(${sharpSansFallback}) format('woff');
    };
    @font-face {
     font-family: 'Sharp Sans';
     font-style: normal;
     font-weight: 500;
     font-display: swap;
     src: local('Sharp Sans Medium'), local('SharpSans-Medium'), url(${sharpSansMedium}) format('woff2'),
     url(${sharpSansMediumFallback}) format('woff');
    };
    @font-face {
     font-family: 'Sharp Sans';
     font-style: normal;
     font-weight: 600;
     font-display: swap;
     src: local('Sharp Sans Semibold'), local('SharpSans-Semibold'), url(${sharpSansSemiBold}) format('woff2'),
     url(${sharpSansSemiBoldFallback}) format('woff');
    };
    @font-face {
     font-family: 'Sharp Sans';
     font-style: normal;
     font-weight: bold;
     font-display: swap;
     src: local('Sharp Sans Bold'), local('SharpSans-Bold'), url(${sharpSansBold}) format('woff2'),
     url(${sharpSansBoldFallback}) format('woff');
    };

  @font-face {
    font-family: 'PlusJakartaSans';
    src: url(${plusJakartaSansBold}) format('woff2');
    font-weight: 700;
    font-style: normal;
  };

	@font-face {
    font-family: 'PlusJakartaSans';
    src: url(${plusJakartaSansMedium}) format('woff2');
    font-weight: 600;
    font-style: normal;
  };

  @font-face {
    font-family: 'PlusJakartaSans';
    src: url(${plusJakartaSansRegular}) format('woff2');
    font-weight: 500;
    font-style: normal;
  };

  @font-face {
    font-family: 'PlusJakartaSans';
    src: url(${plusJakartaSansRegular}) format('woff2');
    font-weight: normal;
    font-style: normal;
  };

    margin: 0;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.fontSize};
    font-weight: ${({ theme }) => theme.fontWeight};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ReactModal__Overlay {
    z-index: 10;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .table-checkbox {
    width: 48px
  }

  .capitalize {
    text-transform: capitalize;
  }

  .absolute-content {
    position: relative;
    padding-left: 16px;
  }

  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-nowrap {
    white-space: nowrap;
  }

  * {
    box-sizing: border-box;
  }

  .flex {
    display: flex;
  }

  .flex-right {
    justify-content: flex-end;
  }

  .td-flex-height {
    line-height: 28px;
  }

  .align-center {
    align-items: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-center {
    justify-content: center;
  }

  .pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.fontSize};
    font-weight: ${({ theme }) => theme.fontWeight};
    justify-content: center;
  }

  .pagination > li {
    cursor: pointer;
    display: inline-block;
    list-style-type: none;
  }

  .pagination > li > a {
    display: inline-flex;
    padding: 10px;
  }

  .pagination > li.active > a {
    background-color: ${({ theme }) => theme.colors.main};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
  }

  .select-dropdown__clear-indicator {
    cursor: pointer;
    padding: 6px;
  }

   /* This is a quick fix to center the only item of the Topnav menu */
  .miranda-topnav-subitem > button > :nth-child(2) {
    margin: 0 !important;
  }
  .miranda-topnav-subitem-button > :nth-child(2) {
    margin: 0 !important;
  }

  /*Seems like we need to reset some styles in order to the tab without leading icon to render nothing. */
  .miranda-tabs li {
    list-style: none;
  }

  .relative {
    position: relative;
  }

  .hidden {
    display: none;
  }

  .h-full {
    height: 100%;
  }

  .w-full {
    width: 100%;
  }

  .mr-auto {
    margin-right: auto;
  }

  .ml-auto {
    margin-left: auto;
  }

  .overflow-visible {
    overflow: visible
  }

  .cursor-pointer {
    cursor: pointer
  }

  /* Beamer notification indicator. We did that to mimick Miranda badge behavior */
  .beamerTrigger .beamer_icon.active {
    background-color: ${toCSSValue('color-danger-60')};
    color: ${toCSSValue('color-neutral-0')};
    font-size: ${toCSSValue('font-size-1')};
    left: calc(100% - 6px);
    animation: none;
    font-family: 'Sharp Sans';
    font-weight: ${toCSSValue('font-weight-regular')};
    line-height: 12px;
    top: 0;
    inline-size: 12px;
    block-size: 12px;
    transition: none;
  }
`

export const MirandaPageGlobalStyle = createGlobalStyle`
  body {
    overflow: hidden
  }
  main {
    padding: 0 !important;
    background-color: ${toCSSValue('color-background-secondary')} !important;
  }
`

import { createContext, useContext, useState } from 'react'
import type { Dispatch, ReactNode, SetStateAction } from 'react'

type spotQuotesContextProps = {
  isNewQuoteFormOpen: boolean
  setNewQuoteFormOpen: Dispatch<SetStateAction<boolean>>
  duplicatedQuote: SpotQuote
  setDuplicatedQuote: Dispatch<SetStateAction<SpotQuote>>
  isModeCreate: boolean
  setIsModeCreate: Dispatch<SetStateAction<boolean>>
}

const providerValue = {
  isNewQuoteFormOpen: false,
  setNewQuoteFormOpen: () => undefined,
  duplicatedQuote: {} as SpotQuote,
  setDuplicatedQuote: () => undefined,
  isModeCreate: true,
  setIsModeCreate: () => undefined,
}

const spotQuoteContext = createContext<spotQuotesContextProps>(providerValue)

export const useSpotQuoteContext = () => useContext(spotQuoteContext)

export function SpotQuoteProvider({
  children,
  initialProviderValue = {
    duplicatedQuote: providerValue.duplicatedQuote,
    isModeCreate: providerValue.isModeCreate,
  },
}: {
  children: ReactNode
  initialProviderValue?: {
    duplicatedQuote: SpotQuote
    isModeCreate: boolean
  }
}) {
  const [isNewQuoteFormOpen, setNewQuoteFormOpen] = useState(
    providerValue.isNewQuoteFormOpen
  )
  const [duplicatedQuote, setDuplicatedQuote] = useState(
    initialProviderValue.duplicatedQuote
  )
  const [isModeCreate, setIsModeCreate] = useState(
    initialProviderValue.isModeCreate
  )

  const values: spotQuotesContextProps = {
    isNewQuoteFormOpen,
    setNewQuoteFormOpen,
    duplicatedQuote,
    setDuplicatedQuote,
    isModeCreate,
    setIsModeCreate,
  }

  return (
    <spotQuoteContext.Provider value={values}>
      {children}
    </spotQuoteContext.Provider>
  )
}

import type { ReactNode } from 'react'
import styled from 'styled-components'
import type { InterpolationFunction } from 'styled-components'

import PublicHeader from 'components/Header/PublicHeader'
import MessageCenter from 'components/MessageCenter'
import ShipperGuideNav from 'components/PageWithSideNav/ShipperGuideNav'
import { pageWrapperStyle } from 'styles/page'

const StyledPage = styled.main<{ color?: string }>`
  background-color: ${({ color, theme }) => color || theme.colors.white};
  ${pageWrapperStyle}
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 40px;
`
const Footer = styled.footer`
  background-color: ${({ color, theme }) => color || theme.colors.white};
  margin-top: 30px;
`
interface PageProps {
  children: ReactNode
  color?: string
  padding?: string | InterpolationFunction<any>
}

const Page = ({ children, color, padding }: PageProps) => {
  return (
    <ShipperGuideNav color={color} padding={padding}>
      <MessageCenter />
      {children}
    </ShipperGuideNav>
  )
}

export const LoggedOutPage = ({ children, color }: PageProps) => {
  return (
    <>
      <PublicHeader />
      <StyledPage color={color}>
        <MessageCenter />
        {children}
        <Footer />
      </StyledPage>
    </>
  )
}

export default Page

import { SideNavigation, Tag } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'

import { useSettings } from 'contexts/settings'
import { AppRoutes } from 'router/AppRoutes'
import { USER_TYPE } from 'utils/constants'
import userWrapper from 'utils/user'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

type Props = {
  enablePriceMonitor: boolean
}

export const AnalyticsMenu = ({ enablePriceMonitor }: Props) => {
  const history = useHistory()

  const {
    values: [enableInsightsAI],
  } = useSettings(['flags.ENABLE_FREIGHT_INSIGHTS_AI'])

  if (userWrapper.isUserType([USER_TYPE.SG])) {
    return (
      <SideNavigation.Menu label="Analytics">
        {enableInsightsAI && (
          <SideNavigation.Menu.Item
            active={isActivePath(AppRoutes.Insights)}
            label="FreightIntel AI"
            url={AppRoutes.Insights}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked('Insights AI', AppRoutes.Insights, history)
            }}
            trailing={
              <Tag size="small" variant="success">
                BETA
              </Tag>
            }
          />
        )}
        {enablePriceMonitor && (
          <SideNavigation.Menu.Item
            active={isActivePath(AppRoutes.PriceMonitor)}
            label="Price Monitor"
            url={AppRoutes.PriceMonitor}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked(
                'Price Monitor',
                AppRoutes.PriceMonitor,
                history
              )
            }}
          />
        )}
        <SideNavigation.Menu.Item
          active={isActivePath(AppRoutes.ShipperReports)}
          label="Reports"
          url={AppRoutes.ShipperReports}
          onClick={(event) => {
            event.preventDefault()
            navbarLinkClicked('Reports', AppRoutes.ShipperReports, history)
          }}
        />
      </SideNavigation.Menu>
    )
  }

  return null
}

import type { ReactNode } from 'react'
import styled from 'styled-components'

export const Wrapper = styled.label`
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  text-transform: uppercase;
`
export const Text = styled.p`
  margin-bottom: 8px;
`

function Label({ name, children }: { name?: string; children: ReactNode[] }) {
  const [label, ...elements] = children
  return (
    <Wrapper htmlFor={name}>
      <Text>{label}</Text>
      {elements}
    </Wrapper>
  )
}

export default Label

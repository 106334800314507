import type { TooltipPosition } from '@loadsmart/loadsmart-ui'
import { Tooltip } from '@loadsmart/loadsmart-ui'
import styled, { css } from 'styled-components'

const StyledTooltip = styled(Tooltip)<{ breakWord?: boolean }>`
  ${({ breakWord }) =>
    breakWord &&
    css`
      span[role='tooltip'] {
        word-wrap: break-word;
      }
    `}
`

interface Props {
  value: string | null
  limiter: number
  breakWord?: boolean
  position?: TooltipPosition
}

function StringLimiter({ value, limiter, breakWord, position }: Props) {
  if (value === null) {
    return value
  }
  if (value.length <= limiter) {
    return value
  }
  return (
    <StyledTooltip message={value} breakWord={breakWord} position={position}>
      <span style={{ whiteSpace: 'nowrap' }}>
        {`${value.substring(0, limiter)} ...`}
      </span>
    </StyledTooltip>
  )
}

export default StringLimiter

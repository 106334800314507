import type { ShipmentsListFilters } from 'services/shipments'
import { cleanEmptyValues } from 'utils/cleanEmptyValues'
import { plural } from 'utils/strings'

import type { LaneProposal, Shipment } from './types'

export function isLaneProposalRejected(laneProposal: LaneProposal) {
  return laneProposal.status === 'tender_rejected'
}

export function adaptDrayageStops(shipments: Shipment[]) {
  return shipments.map((shipment) => {
    if (shipment.mode.toLowerCase() === 'dray') {
      const [pickup, delivery] = shipment.stops

      return {
        ...shipment,
        stops: [{ ...pickup }, { ...delivery }],
      }
    }

    return shipment
  })
}

export function filtersMapper(filters?: ShipmentsListFilters) {
  if (!filters) {
    return {}
  }

  return Object.keys(filters).reduce((acc, curr) => {
    const value = filters[curr as keyof ShipmentsListFilters]
    // parse string query params to boolean values
    if (value === 'true' || value === 'false') {
      return {
        ...acc,
        [curr]: value === 'true',
      }
    }

    return {
      ...acc,
      [curr]: value,
    }
  }, {})
}

export function formatRequestSchedulingIgnoredShipments(
  shipmentsIgnored: number
) {
  const shipmentsIgnoredFormatted = plural('shipment', shipmentsIgnored)

  return `${shipmentsIgnored} ${shipmentsIgnoredFormatted} will be ignored due to no pending appointment`
}

export function getActionButtonVariant(hasSelectedShipments: boolean) {
  return hasSelectedShipments ? 'secondary' : 'primary'
}

export function requestSchedulingMapper(
  shipments: string | string[],
  filters: ShipmentsListFilters | undefined
) {
  const payload = Array.isArray(shipments)
    ? { shipment_uuids: shipments }
    : { all: true, filters: cleanEmptyValues(filters ?? {}) }

  return payload
}

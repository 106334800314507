import * as Yup from 'yup'

import { REQUIRED_FIELD_MESSAGE } from 'constants/errors'

export const validationSchema = Yup.object().shape({
  company_name: Yup.string()
    .notOneOf(
      [Yup.ref('facility_address.address')],
      'Facility name cannot be its address. Please enter a valid name.'
    )
    .required('Facility name is a required field'),
  facility_address: Yup.object()
    .shape({
      address: Yup.string().required('Must be a full address'),
      city: Yup.string(),
      state: Yup.string(),
      zipcode: Yup.string().required('Must be a valid zip code'),
      lat: Yup.string(),
      lng: Yup.string(),
      country: Yup.string(),
    })
    .required(REQUIRED_FIELD_MESSAGE),
  mode: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  operating_days: Yup.array().required(REQUIRED_FIELD_MESSAGE),
  operating_time_from: Yup.date().required(REQUIRED_FIELD_MESSAGE),
  operating_time_to: Yup.date().required(REQUIRED_FIELD_MESSAGE),
  notes: Yup.string(),
})

import moment from 'moment'

import type { FormValue } from './types'

export const defaultInitialValues: FormValue = {
  company_name: '',
  mode: 'APPT',
  facility_address: undefined,
  operating_days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
  operating_time_from: moment().add(2, 'days').startOf('hour').set({ hour: 9 }),
  operating_time_to: moment().add(2, 'days').startOf('hour').set({ hour: 16 }),
  notes: undefined,
}

export const daysOptions = [
  { value: 'monday', label: 'MON' },
  { value: 'tuesday', label: 'TUE' },
  { value: 'wednesday', label: 'WED' },
  { value: 'thursday', label: 'THU' },
  { value: 'friday', label: 'FRI' },
  { value: 'saturday', label: 'SAT' },
  { value: 'sunday', label: 'SUN' },
]

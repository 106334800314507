import type { QueryObserverOptions, UseQueryResult } from 'react-query'
import { useQuery } from 'react-query'

import type { FacilityDetailsV2 } from 'services/facilities'
import { getFacilityDetails } from 'services/facilities'

export function useFacilityDetailsV2(
  facilityUUID?: string | null,
  options?: QueryObserverOptions<FacilityDetailsV2>
): UseQueryResult<FacilityDetailsV2> {
  return useQuery({
    queryKey: ['getFacilityDetailsV2', facilityUUID],
    queryFn() {
      return getFacilityDetails(facilityUUID!)
    },
    enabled: facilityUUID != null,
    refetchInterval: false,
    staleTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  })
}

import httpClient from 'utils/httpClient'
import toArray from 'utils/toArray'

export const PRICE_ITEM_TYPES_ENDPOINT = '/accounting/price-item-types'

export type PriceItemTypesOptions = {
  mode?: TransportationModeCode | TransportationModeCode[]
  usage?: AccountingObjectType | AccountingObjectType[]
}

export const getPriceItemTypesURL = (options?: PriceItemTypesOptions) => {
  const modes = toArray(options?.mode)
  const usages = toArray(options?.usage)
  let qs = ''

  modes.forEach((mode, index) => {
    qs = `${qs}${index > 0 ? '&' : ''}modes=${mode}`
  })

  usages.forEach((usage, index) => {
    qs = `${qs}${modes.length > 0 || index > 0 ? '&' : ''}usage=${usage}`
  })

  return `${PRICE_ITEM_TYPES_ENDPOINT}?${qs}`
}

export const priceItemTypes = async (options?: PriceItemTypesOptions) => {
  const { data } = await httpClient.get<PriceItemType[]>(
    getPriceItemTypesURL(options)
  )

  return data
}

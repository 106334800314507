import { Field, Layout, Text } from '@loadsmart/miranda-react'

import openDockLogo from 'assets/imgs/opendock-logo.png'

export default function WarehouseSection({
  facility,
}: {
  facility?: Pick<FacilityDetails, 'warehouse_uuid' | 'warehouse_name'>
}) {
  const { warehouse_uuid, warehouse_name } = facility || {}
  if (!warehouse_uuid) {
    return null
  }

  return (
    <Layout.Group gap="spacing-30">
      <Field label="Integrated with">
        <img src={openDockLogo} alt="OpenDock" width={105} />
      </Field>
      <Field label="Opendock name">
        <Text variant="body-md" color="color-text-primary">
          {warehouse_name}
        </Text>
      </Field>
      <Field label="Opendock id">
        <Text variant="body-md" color="color-text-primary">
          {warehouse_uuid}
        </Text>
      </Field>
    </Layout.Group>
  )
}

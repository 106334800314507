import { IconQuestionCircle } from '@loadsmart/icons'
import { Layout, Text, Tooltip } from '@loadsmart/miranda-react'
import type { ColorToken } from '@loadsmart/miranda-react/dist/tokens'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { createContext, useContext } from 'react'
import type { HTMLProps, PropsWithChildren, ReactNode } from 'react'

export function SummaryLabelValue(
  props: Omit<HTMLProps<HTMLElement>, 'ref' | 'label' | 'value'> & {
    inline?: boolean
    label?: ReactNode
    value?: ReactNode
    tip?: ReactNode
    color?: ColorToken
  }
) {
  const { inline, label, value, tip, color, ...rest } = props
  // temporary until LabeledValue supports ReactNode
  const Wrapper = inline ? Layout.Group : Layout.Stack

  return (
    <Wrapper {...rest} gap="spacing-2" align="baseline">
      {label != null && (
        <Layout.Group gap="spacing-1" align="flex-start">
          <Text variant="body-sm" color="color-text-tertiary">
            {label}&nbsp;
          </Text>
          {tip && (
            <>
              <Tooltip message={tip} trigger="hover" placement="right">
                <IconQuestionCircle
                  title="More information"
                  color={toCSSValue('color-text-tertiary')}
                  width={16}
                  height={16}
                />
              </Tooltip>
              &nbsp;
            </>
          )}
        </Layout.Group>
      )}
      <Text variant="body-md" color={color || 'color-text-primary'}>
        {value}
      </Text>
    </Wrapper>
  )
}

export function createSummarySetup<T>({
  contextName,
}: {
  contextName: string
}) {
  const SummaryContext = createContext<T>({} as T)
  SummaryContext.displayName = contextName

  function SummaryProvider({
    value,
    children,
  }: PropsWithChildren<{
    value: T
  }>) {
    return (
      <SummaryContext.Provider value={value}>
        {children}
      </SummaryContext.Provider>
    )
  }

  function useSummaryContext() {
    const context = useContext(SummaryContext)

    if (context === undefined) {
      throw new Error(
        `SummaryContext must be used within the SummaryProvider for the ${contextName} context.`
      )
    }

    return context
  }

  return {
    SummaryContext,
    SummaryProvider,
    useSummaryContext,
  }
}

import { IconLogout } from '@loadsmart/icons'
import { Text } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import userWrapper from 'utils/user'

import { StyledDropdownItem } from './styles'

export const LogoutButton = () => (
  <StyledDropdownItem
    leading={
      <IconLogout
        width={16}
        height={16}
        fill={toCSSValue('color-primary-60')}
      />
    }
    onClick={(event) => {
      event.preventDefault()
      userWrapper.logout()
    }}
  >
    <Text variant="body-md-bold">Logout</Text>
  </StyledDropdownItem>
)

import { Field, TextField } from '@loadsmart/miranda-react'
import { useFormikContext } from 'formik'
import { useState } from 'react'

import { FormField } from 'components/FormField'
import { noop } from 'utils/noop'

import FacilitySearchField from './FacilitySearchField'
import type { FormValue } from './types'
import { createFacilityAddress } from './utils'

export default function FacilityAddressField({
  addressError,
}: {
  addressError: string
}) {
  const [enableFacilitySearch, toggleEnableFacilitySearch] = useState(true)

  const { touched, setFieldValue, values, setFieldTouched } =
    useFormikContext<FormValue>()

  if (enableFacilitySearch) {
    return (
      <FormField
        label="Facility street address"
        name="facility_address"
        touched={!!touched.facility_address}
        error={addressError}
        span={2}
        required
        data-testid="facility_address"
        component={
          <FacilitySearchField
            id="facility_address"
            placeholder="Insert facility street address"
            value={createFacilityAddress(values.facility_address)}
            onAddManually={(suggestion) => {
              setFieldValue('facility_address', {
                address: suggestion,
              })
              toggleEnableFacilitySearch(false)
            }}
            callback={(suggestion) => {
              if (suggestion) {
                const location: Address = {
                  address: suggestion.address,
                  city: suggestion.city,
                  state: suggestion.state,
                  zipcode: suggestion.zipcode,
                  country: suggestion.country,
                }

                if (suggestion.isFacility) {
                  location.company_name = suggestion.company_name
                } else {
                  location.lat = suggestion.lat
                  location.lng = suggestion.lng
                }

                setFieldValue(
                  'facility_address',
                  createFacilityAddress(location)
                )
              }

              toggleEnableFacilitySearch(false)
            }}
            onFocus={noop}
            onBlur={() => {
              setFieldTouched('facility_address')
            }}
          />
        }
      />
    )
  }

  return (
    <Field label="Facility street address" required>
      <TextField
        id="facility_address.address"
        type="text"
        aria-label="address"
        value={values.facility_address?.address || ''}
        onInput={(event) => {
          setFieldValue(
            `facility_address.address`,
            (event.target as HTMLInputElement).value
          )
        }}
      />
    </Field>
  )
}

import { SideNavigation } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'

import { AppRoutes } from 'router/AppRoutes'
import { USER_TYPE } from 'utils/constants'
import userWrapper from 'utils/user'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

export const QuotesMenu = () => {
  const history = useHistory()

  return (
    <SideNavigation.Menu>
      {userWrapper.isUserType([USER_TYPE.SG, USER_TYPE.FBM]) && (
        <>
          <SideNavigation.Menu.Item
            active={isActivePath('/quotes/new')}
            label="New Quote"
            url="/quotes/new"
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked('New Quote', '/quotes/new', history)
            }}
          />
          <SideNavigation.Menu.Item
            active={isActivePath(AppRoutes.Quotes)}
            label="Quotes"
            url={AppRoutes.Quotes}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked('Quotes', AppRoutes.Quotes, history)
            }}
          />
        </>
      )}

      {userWrapper.isUserType([USER_TYPE.SG, USER_TYPE.FBM, USER_TYPE.MTC]) && (
        <SideNavigation.Menu.Item
          active={isActivePath(AppRoutes.Shipments)}
          label="Shipments"
          url={AppRoutes.Shipments}
          onClick={(event) => {
            event.preventDefault()
            navbarLinkClicked('Shipments', AppRoutes.Shipments, history)
          }}
        />
      )}
    </SideNavigation.Menu>
  )
}

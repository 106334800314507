import type { AxiosRequestConfig } from 'axios'

import analytics from 'utils/analytics'
import httpClient from 'utils/httpClient'
import user, { formatUserData } from 'utils/user'

export const obtainToken = async (
  username: string,
  password: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.post(
    '/auth/token',
    {
      username,
      password,
    },
    config
  )

  user.setTokens(data)
}

export const refreshToken = async () => {
  const { data } = await httpClient.post('/auth/token/refresh', {
    refresh: user.refreshToken,
    username: user.data.user_email,
  })
  user.setTokens(data)
}

export const sendPasswordResetRequest = async (email: string) => {
  const { data } = await httpClient.post('/auth/password_reset', { email })
  return data
}

export const resetPassword = async (password: string, token: string) => {
  const { data } = await httpClient.post('/auth/password_reset/confirm', {
    password,
    token,
  })
  user.setTokens(data)
}

export const changePassword = async (
  current_password: string,
  new_password: string,
  new_password_confirmation: string
) => {
  const { data } = await httpClient.put('/auth/change_password', {
    current_password,
    new_password,
    new_password_confirmation,
  })
  user.setTokens(data)
}

export const completeRegistration = async (password: string, token: string) => {
  const { data } = await httpClient.post('/auth/complete_registration', {
    password,
    token,
  })
  user.setTokens(data)
}

export const emailVerification = async (token: string) => {
  const { data } = await httpClient.post('/auth/email_verification', {
    token,
  })
  return data
}

export const resendEmailVerification = async (email: string) => {
  const { data } = await httpClient.post('/auth/resend_email_verification', {
    email,
  })
  return data
}

export const fetchUserData = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get('/auth/user_data', config)

  const userData = formatUserData({
    userData: data,
    accessToken: user.accessToken,
    refreshToken: user.refreshToken,
    exp: user.parsedAccess.exp,
  })

  user.setUser(userData)
  user.setSessionShipperUUID(userData)
  analytics.setUser(userData)
}

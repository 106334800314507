import { SUPPORTED_COUNTRIES_METADATA } from 'components/FacilityDetails/FacilityDetails.constants'
import { createTransient } from 'utils/transient'

import type { TransientFacilityContact } from './FacilityContactsForm.types'

export const CONTACT_COUNTRY_OPTIONS = [
  { value: 'USA', ...SUPPORTED_COUNTRIES_METADATA.USA },
  { value: 'CAN', ...SUPPORTED_COUNTRIES_METADATA.CAN },
]

export function createTransientFacilityContact(
  overrides?: Partial<TransientFacilityContact>
) {
  return createTransient({
    name: '',
    country: '',
    phone: '',
    extension: '',
    email: '',
    ...overrides,
  })
}

export function isContactEmpty(contact: TransientFacilityContact) {
  return !contact.name && !contact.phone && !contact.extension && !contact.email
}

import { Card, Tabs } from '@loadsmart/miranda-react'

import type { AccessorialsOptionsByStop } from 'components/Accessorials/AccessorialsSelectByStop'
import { AccessorialsSelectByStop } from 'components/Accessorials/AccessorialsSelectByStop'

import type { TransientAccessorialsByModeAndStop } from './Facility.types'

export type AccessorialsSectionProps = {
  collapsible?: boolean
  initialCollapsed?: boolean
  accessorials: TransientAccessorialsByModeAndStop
  onChange: (accessorials: TransientAccessorialsByModeAndStop) => void
}

export function AccessorialsSection(props: AccessorialsSectionProps) {
  const { collapsible, initialCollapsed, accessorials, onChange } = props

  const onSelectChange = ({
    mode,
    selected,
  }: {
    mode: 'FTL' | 'LTL' | 'PTL'
    selected: AccessorialsOptionsByStop
  }) => {
    onChange({ ...accessorials, [mode]: selected })
  }

  return (
    <Card
      collapsible={collapsible}
      initialCollapsed={initialCollapsed}
      data-testid="accessorials-section"
    >
      <Card.Title role="heading" aria-level={2}>
        Accessorials
      </Card.Title>
      <Card.Subtitle role="heading" aria-level={3}>
        Set default accessorials for new shipments handled by this facility
      </Card.Subtitle>

      <Card.Divider />

      <Card.Body>
        <Tabs>
          <Tabs.Tab name="FTL">FTL</Tabs.Tab>
          <Tabs.Tab name="LTL">LTL</Tabs.Tab>
          <Tabs.Tab name="PTL">PTL</Tabs.Tab>
          <Tabs.Panel forTab="FTL" data-testid="FTL-accessorials">
            <AccessorialsSelectByStop
              usage="facility"
              mode="full_truck_load"
              selectedAccessorials={accessorials.FTL}
              onChange={(selected) => {
                onSelectChange({ mode: 'FTL', selected })
              }}
            />
          </Tabs.Panel>
          <Tabs.Panel forTab="LTL" data-testid="LTL-accessorials">
            <AccessorialsSelectByStop
              usage="facility"
              mode="less_than_truckload"
              selectedAccessorials={accessorials.LTL}
              onChange={(selected) => {
                onSelectChange({ mode: 'LTL', selected })
              }}
            />
          </Tabs.Panel>
          <Tabs.Panel forTab="PTL" data-testid="PTL-accessorials">
            <AccessorialsSelectByStop
              usage="facility"
              mode="partial_truckload"
              selectedAccessorials={accessorials.PTL}
              onChange={(selected) => {
                onSelectChange({ mode: 'PTL', selected })
              }}
            />
          </Tabs.Panel>
        </Tabs>
      </Card.Body>
    </Card>
  )
}

import { Text } from '@loadsmart/miranda-react'

import type { FacilityDetailsV2 } from 'services/facilities'

export function FacilityName({ name }: Pick<FacilityDetailsV2, 'name'>) {
  return (
    <Text
      variant="body-lg-bold"
      color="color-text-primary"
      data-testid="facility-name"
    >
      {name}
    </Text>
  )
}

import {
  Banner,
  Card,
  Drawer,
  Icon,
  Layout,
  LogoLoader,
} from '@loadsmart/miranda-react'
import { useEffect } from 'react'

import { FacilityContactsTable } from 'components/FacilityDetails/FacilityContactsTable'
import { FacilitySummaryCard } from 'components/FacilityDetails/FacilitySummaryCard'
import { useFacilityDetailsV2 } from 'components/FacilityDetails/useFacilityDetailsV2'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

type ContactsDetailsBodyProps = {
  facilityUUID: string
}

function ContactsDetailsBody({ facilityUUID }: ContactsDetailsBodyProps) {
  const {
    data: facility,
    isLoading,
    isError,
    refetch,
  } = useFacilityDetailsV2(facilityUUID)

  if (isLoading) {
    return (
      <>
        <Card>
          <Card.Body>
            <Layout.Group justify="center">
              <LogoLoader size="32px" />
            </Layout.Group>
          </Card.Body>
        </Card>

        <Card>
          <Card.Title>Contacts</Card.Title>
          <Card.Divider />
          <Card.Body>
            <Layout.Group justify="center">
              <LogoLoader aria-label="Loading facility contacts" size="32px" />
            </Layout.Group>
          </Card.Body>
        </Card>
      </>
    )
  }

  if (isError || !facility) {
    return (
      <Banner variant="danger">
        <Banner.Title>Failed to load the facility contacts</Banner.Title>
        <Banner.Actions>
          <Banner.ActionPrimary onClick={() => refetch()}>
            Try again
          </Banner.ActionPrimary>
        </Banner.Actions>
      </Banner>
    )
  }

  return (
    <>
      <FacilitySummaryCard facility={facility} />

      <Card>
        <Card.Title>Contacts</Card.Title>
        <Card.Divider />
        <Card.Body>
          <FacilityContactsTable contacts={facility.contacts} />
        </Card.Body>
      </Card>
    </>
  )
}

type ContactsDetailsProps = {
  facilityUUID: string
  onEditContacts: () => void
}

export function ContactsDetails({
  facilityUUID,
  onEditContacts,
}: ContactsDetailsProps) {
  useEffect(() => {
    analytics.track(
      AnalyticsEvent.ContactsDrawerDetailsView,
      AnalyticsEventTrigger.view
    )
  }, [facilityUUID])

  return (
    <>
      <Drawer.Header role="heading" data-testid="contacts-details-header">
        Contacts
        <Drawer.Close aria-label="Close contacts drawer" />
      </Drawer.Header>

      <Drawer.Body>
        <Layout.Stack gap="spacing-6">
          <ContactsDetailsBody facilityUUID={facilityUUID} />
        </Layout.Stack>
      </Drawer.Body>

      <Drawer.Actions>
        <Drawer.ActionTertiary
          leading={<Icon name="pencil" size="16" />}
          onClick={onEditContacts}
        >
          Edit contacts
        </Drawer.ActionTertiary>
      </Drawer.Actions>
    </>
  )
}

import { Link as BaseLink } from 'react-router-dom'
import type {
  FlattenInterpolation,
  ThemeProps,
  DefaultTheme,
} from 'styled-components'
import styled, { css } from 'styled-components'

import { hideStyledProps } from 'utils/styles'

export type ButtonVariant =
  | 'main'
  | 'secondary'
  | 'light'
  | 'dark'
  | 'darker'
  | 'danger'
  | 'dangerSecondary'
  | 'dangerDark'
  | 'neutral'
  | 'neutralLight'
  | 'mainLight'
  | 'blank'
  | 'neutralSeparated'

interface ButtonProps {
  disabled?: boolean
  variant: ButtonVariant
}

const mainCSS = css`
  background: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white};
`
const secondaryCSS = css`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.borderSecondary};
`
const lightCSS = css`
  background: ${({ theme }) => theme.colors.backgroundLight};
  color: ${({ theme }) => theme.colors.neutralDarker};
`
const dangerCSS = css`
  background: ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.white};
`
const dangerDarkCSS = css`
  background: ${({ theme }) => theme.colors.dangerDarker};
  color: ${({ theme }) => theme.colors.white};
`
const darkCSS = css`
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  color: ${({ theme }) => theme.colors.white};
`
const dangerSecondaryCSS = css`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.danger};
`
const neutralCSS = css`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.neutral};
  color: ${({ theme }) => theme.colors.neutral};
`
const neutralLightCSS = css`
  background: ${({ theme }) => theme.colors.backgroundMediumGray};
  color: ${({ theme }) => theme.colors.neutralDarker};
`
const mainLightCSS = css`
  background: ${({ theme }) => theme.colors.successLight};
  color: ${({ theme }) => theme.colors.main};
`
const darkerCSS = css`
  background: ${({ theme }) => theme.colors.neutralDarker};
  color: ${({ theme }) => theme.colors.white};
`
const blankCSS = css`
  background: transparent;
  color: ${({ theme }) => theme.colors.neutral};
  width: auto;
`

const styles: Record<
  ButtonVariant,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  main: mainCSS,
  secondary: secondaryCSS,
  light: lightCSS,
  danger: dangerCSS,
  dangerDark: dangerDarkCSS,
  dark: darkCSS,
  dangerSecondary: dangerSecondaryCSS,
  neutral: neutralCSS,
  neutralSeparated: neutralCSS,
  neutralLight: neutralLightCSS,
  mainLight: mainLightCSS,
  darker: darkerCSS,
  blank: blankCSS,
}

export function getButtonStyle({ variant }: ButtonProps) {
  return styles[variant]
}
export const baseCSS = css`
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 35px;
  line-height: 16px;
  text-transform: uppercase;
  width: 100%;
`
export const disabledCSS = css`
  cursor: auto;
  opacity: 0.5;
  pointer-events: none;
`

/**
 * @deprecated Use @loadsmart/miranda-react instead
 */
export default styled.button<ButtonProps>`
  ${baseCSS}
  ${getButtonStyle}

  &:disabled {
    ${disabledCSS}
  }
`
export const Link = styled(BaseLink)
  .withConfig(hideStyledProps(['variant']))
  .attrs<ButtonProps>(({ disabled }) => ({
    'aria-disabled': disabled,
  }))<ButtonProps>`
  ${baseCSS}
  ${getButtonStyle}
  ${({ disabled }) => disabled && disabledCSS}
  border: none;
  margin: 5px;
  padding: 8px;
  text-decoration: none;
`
export const UnstyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  &:disabled {
    ${disabledCSS}
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
`

import { Select } from '@loadsmart/loadsmart-ui'
import type { SelectProps } from '@loadsmart/loadsmart-ui'
import {
  Banner,
  Button,
  Card,
  Checkbox,
  Divider,
  Field,
  Icon,
  Layout,
  Tag,
  Text,
  Toggle,
  ToggleGroup,
} from '@loadsmart/miranda-react'
import { get } from 'lodash'
import { Fragment } from 'react'

import { useTransientField } from '_shared_/hooks/useTransientField'
import { createTransientCollectionSetup } from 'components/TransientCollectionForm'
import type { FacilityAppointmentMode } from 'services/facilities'
import { plural } from 'utils/strings'
import { getTransientErrorsCount } from 'utils/transient'

import type {
  TransientFacilityHoursOfOperation,
  WeeklyTransientOperatingHours,
} from './Facility.types'
import {
  TIME_OPTIONS,
  createDailyTransientOperatingHours,
  createTransientOperatingHours,
  createWeeklyTransientOperatingHours,
  getSchedulingPolicyOptions,
  getTimeOption,
  getTimeOptions,
  validateClosingTime,
  validateOpeningTime,
} from './OperatingHoursSection.helpers'
import { ContentWrapper } from './OperatingHoursSection.styles'
import { useOperatingHoursManager } from './useOperatingHoursManager'

const { TransientCollectionForm, useTransientCollectionFormContext } =
  createTransientCollectionSetup<TransientFacilityHoursOfOperation>()

function AddOperatingHours({
  index,
  disabled,
}: {
  index: number
  disabled?: boolean
}) {
  const [, dispatch] = useTransientCollectionFormContext()

  return (
    <Button
      aria-label="Add operating hours"
      title="Add operating hours"
      variant="icon-secondary"
      disabled={disabled}
      onClick={() => {
        // we want the new operating hours to be inserted after the current one
        dispatch({ type: 'ADD_ITEM', payload: { addAt: index + 1 } })
      }}
    >
      <Icon name="plus" size="20" />
    </Button>
  )
}

function RemoveOperatingHours({
  index,
  disabled,
}: {
  index: number
  disabled?: boolean
}) {
  const [, dispatch] = useTransientCollectionFormContext()

  return (
    <Button
      aria-label="Remove operating hours"
      title="Remove operating hours"
      variant="icon-secondary"
      disabled={disabled}
      onClick={() => {
        dispatch({ type: 'REMOVE_ITEM', payload: { index } })
      }}
    >
      <Icon name="trash" size="20" />
    </Button>
  )
}

function TimeSelect(props: Partial<SelectProps>) {
  const { options, ...rest } = { options: TIME_OPTIONS, ...props }

  /**
   * the `hideClear` is to save space rather than preventing the user
   * from actually clearing the value.
   */
  return (
    <Select
      name="time"
      options={options}
      {...rest}
      hideClear
      style={{ width: 130 }}
    />
  )
}

function OperatingHoursRow(props: {
  closingTimeOptions: SelectOption[]
  disabled: boolean
  index: number
  openingTimeOptions: SelectOption[]
  operatingHour: TransientFacilityHoursOfOperation
}) {
  const {
    closingTimeOptions,
    disabled,
    index,
    openingTimeOptions,
    operatingHour,
  } = props
  const [, dispatch] = useTransientCollectionFormContext()
  const { getFieldProps, getHintProps } = useTransientField(operatingHour)

  return (
    <>
      <Field {...getFieldProps('opens')}>
        <Field.Label>Opening time</Field.Label>

        <TimeSelect
          placeholder="Select opening time"
          disabled={disabled}
          options={openingTimeOptions}
          value={getTimeOption(operatingHour.opens)}
          onChange={(event) => {
            const value: string | null = get(event, 'target.value.value', null)

            const { opens, closes } = validateOpeningTime(
              value,
              operatingHour.closes
            )

            dispatch({
              type: 'SET_ITEM',
              payload: {
                index,
                changes: {
                  opens,
                  closes,
                },
              },
            })
          }}
        />
        <Field.Hint {...getHintProps('opens')} />
      </Field>

      <Field {...getFieldProps('closes')}>
        <Field.Label>Closing time</Field.Label>

        <TimeSelect
          placeholder="Select closing time"
          disabled={disabled}
          options={closingTimeOptions}
          value={getTimeOption(operatingHour.closes)}
          onChange={(event) => {
            const value: string | null = get(event, 'target.value.value', null)

            const { opens, closes } = validateClosingTime(
              operatingHour.opens,
              value
            )

            dispatch({
              type: 'SET_ITEM',
              payload: {
                index,
                changes: {
                  opens,
                  closes,
                },
              },
            })
          }}
        />
        <Field.Hint {...getHintProps('closes')} />
      </Field>

      <Field>
        <Field.Label>Schedule policy</Field.Label>

        <ToggleGroup
          type="single-strict"
          disabled={disabled}
          value={operatingHour.appointment_mode as string}
          onChange={(event) => {
            const {
              detail: { value },
            } = event

            dispatch({
              type: 'SET_ITEM',
              payload: {
                index,
                changes: {
                  // this is a hack to get around the fact that the `ToggleGroupChangeEventDetail` is incorrect
                  appointment_mode: value as unknown as FacilityAppointmentMode,
                },
              },
            })
          }}
        >
          {getSchedulingPolicyOptions().map((option) => (
            <Toggle key={option.value} value={option.value}>
              {option.label}
            </Toggle>
          ))}
        </ToggleGroup>
      </Field>
    </>
  )
}

type OperatingHoursManagerProps = {
  weeklyOperatingHours: WeeklyTransientOperatingHours
  onChange?: (weeklyOperatingHours: WeeklyTransientOperatingHours) => void
}

function OperatingHoursManager(props: OperatingHoursManagerProps) {
  const { onChange, weeklyOperatingHours } = props

  const { getDailyOperatingHoursSetup, showNoHoursSetBanner } =
    useOperatingHoursManager({
      weeklyOperatingHours,
      onChange,
    })

  const days = Object.keys(weeklyOperatingHours) as DayOfWeek[]

  return (
    <ContentWrapper>
      {days.map((day) => {
        const {
          canAddOperatingHoursInterval,
          canRemoveOperatingHoursInterval,
          dailyOperatingHours,
          enabled,
          onDailyOperatingHoursChange,
          onEnabledChange,
        } = getDailyOperatingHoursSetup(day)

        return (
          <Fragment key={day}>
            <Layout.Group
              align="flex-start"
              justify="space-between"
              className="daily-operating-hours"
              data-testid="daily-operating-hours"
            >
              <Field className="day-of-week">
                <Field.Label>
                  Day of the week
                  {/* this is just a placeholder to keep the spacing; we hide it using CSS */}
                </Field.Label>

                <Checkbox
                  checked={enabled}
                  onChange={(event) => {
                    const {
                      detail: { checked },
                    } = event

                    onEnabledChange(checked)
                  }}
                >
                  {day}
                </Checkbox>
              </Field>

              <TransientCollectionForm
                items={dailyOperatingHours}
                createItem={createTransientOperatingHours}
                onChange={onDailyOperatingHoursChange}
              >
                <Layout.Stack>
                  {dailyOperatingHours.map((operatingHour, index) => {
                    const { openingTimeOptions, closingTimeOptions } =
                      getTimeOptions(operatingHour.opens, operatingHour.closes)

                    return (
                      <Layout.Group
                        key={operatingHour._metadata.id}
                        align="flex-start"
                        justify="space-between"
                        className="operating-hours-row"
                        data-testid="operating-hours-interval"
                      >
                        <OperatingHoursRow
                          operatingHour={operatingHour}
                          index={index}
                          openingTimeOptions={openingTimeOptions}
                          closingTimeOptions={closingTimeOptions}
                          disabled={!enabled}
                        />

                        <Field className="actions">
                          <Field.Label>
                            Actions
                            {/* this is just a placeholder to keep the spacing; we hide it using CSS */}
                          </Field.Label>

                          <Layout.Group gap="spacing-0-5">
                            <AddOperatingHours
                              index={index}
                              disabled={!canAddOperatingHoursInterval}
                            />
                            <RemoveOperatingHours
                              index={index}
                              disabled={!canRemoveOperatingHoursInterval}
                            />
                          </Layout.Group>
                        </Field>
                      </Layout.Group>
                    )
                  })}
                </Layout.Stack>
              </TransientCollectionForm>
            </Layout.Group>
            <Divider />
          </Fragment>
        )
      })}

      <Banner variant="warning" hidden={!showNoHoursSetBanner}>
        <Banner.Title>
          With no hours set, we default to Monday-Friday, 09:00-16:00.
        </Banner.Title>
      </Banner>
    </ContentWrapper>
  )
}

export type OperatingHoursSectionProps = {
  collapsible?: boolean
  initialCollapsed?: boolean
  weeklyOperatingHours?: WeeklyTransientOperatingHours
  onChange?: OperatingHoursManagerProps['onChange']
}

export const DEFAULT_WEEKLY_OPERATING_HOURS =
  createWeeklyTransientOperatingHours({
    Monday: createDailyTransientOperatingHours({
      enabled: true,
    }),

    Tuesday: createDailyTransientOperatingHours({
      enabled: true,
    }),
    Wednesday: createDailyTransientOperatingHours({
      enabled: true,
    }),
    Thursday: createDailyTransientOperatingHours({
      enabled: true,
    }),
    Friday: createDailyTransientOperatingHours({
      enabled: true,
    }),
    Saturday: createDailyTransientOperatingHours({
      enabled: false,
    }),
    Sunday: createDailyTransientOperatingHours({
      enabled: false,
    }),
  })

export function OperatingHoursSection({
  collapsible,
  initialCollapsed,
  weeklyOperatingHours = DEFAULT_WEEKLY_OPERATING_HOURS,
  onChange,
}: OperatingHoursSectionProps) {
  const errorsCount = getTransientErrorsCount(weeklyOperatingHours)

  return (
    <Card
      collapsible={collapsible}
      initialCollapsed={initialCollapsed}
      data-testid="operating-hours-section"
    >
      <Card.Title role="heading" aria-level={2}>
        <Layout.Group gap="spacing-2" align="center">
          <Text variant="heading-sm-bold" color="color-text-secondary">
            Operating hours
          </Text>
          {errorsCount > 0 && (
            <Tag variant="danger" size="small">
              {plural(`${errorsCount} error`, errorsCount)}
            </Tag>
          )}
        </Layout.Group>
      </Card.Title>
      <Card.Subtitle role="heading" aria-level={3}>
        Set facility hours for receiving and dispatching shipments
      </Card.Subtitle>
      <Card.Divider />
      <Card.Body>
        <OperatingHoursManager
          weeklyOperatingHours={weeklyOperatingHours}
          onChange={onChange}
        />
      </Card.Body>
    </Card>
  )
}

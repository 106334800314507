import { isNil } from '@loadsmart/utils-object'

// TODO: Add to @loadsmart/utils
/**
 * Wraps a non-array value to an array, or returns the value
 * itself if it is already an array.
 * null/undefined values as returned as empty arrays.
 * @param {*} value - Value to be returned as/in array.
 * @returns {Array<*>}
 */
export default function toArray(value) {
  if (isNil(value)) {
    return []
  }

  if (Array.isArray(value)) {
    return value
  }

  return [value]
}

// Gobal
export const GLOBAL_INTERNAL_SERVER_ERROR_MESSAGE =
  'We are experiencing an internal server problem. Please try again in few minutes.'

// Login
export const LOGIN_RAW_BLANK_FORM_ERROR_MESSAGE =
  'This field may not be blank. This field may not be blank.'
export const LOGIN_RAW_SINGLE_BLANK_FIELD_ERROR_MESSAGE =
  'This field may not be blank.'
export const LOGIN_RAW_INVALID_CREDENTIALS_ERROR_MESSAGE =
  'No active account found with the given credentials.'
export const LOGIN_INVALID_CREDENTIALS_SUGGEST_PASSWORD_RESET_ERROR_MESSAGE =
  "Seems like you don't remember your login. Try to reset it by clicking Forgot Your Password."
export const LOGIN_INVALID_CREDENTIALS_ERROR_MESSAGE =
  'Your email or password is incorrect. Please try again.'
export const LOGIN_BLANK_FORM_ERROR_MESSAGE = 'Please fill the form to login.'
export const LOGIN_EXPIRED_PASSWORD_ERROR_MESSAGE =
  'You have not changed your password for 60 days. You must change it now.'

export const REQUIRED_FIELD_MESSAGE = 'Required'
export const POSITIVE_NUMBER_MESSAGE = 'Must be greater than 0'
export const INVALID_NUMBER_MESSAGE = 'Invalid number'
export const INVALID_PHONE_NUMBER_MESSAGE = 'Invalid phone number'
export const INVALID_PHONE_EXTENSION_MESSAGE = 'Invalid phone extension'
export const INVALID_VALUE_MESSAGE = 'Invalid value'
export const INVALID_EMAIL_MESSAGE = 'Invalid email'
export const REQUIRED_VALID_ADDRESS_OR_FACILITY =
  'Please select an address or facility from the list'
export const DATE_CANNOT_BE_IN_THE_PAST = 'Date cannot be in the past'

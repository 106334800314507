import type { LabelProps } from '@loadsmart/loadsmart-ui'
import { Label, Layout } from '@loadsmart/loadsmart-ui'
import type { Spacing } from '@loadsmart/loadsmart-ui/dist/components/Layout/Layout.types'
import { Icon, Tooltip } from '@loadsmart/miranda-react'
import type { ReactNode } from 'react'

import { StyledErrorMessage } from './StyledErrorMessage'

export interface BaseFieldProps {
  label?: string
  labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  error?: string | any[]
  id?: string
  required?: boolean
  className?: string
  children: ReactNode
  space?: Spacing
  tip?: string
}

export function BaseField({
  error,
  id,
  label,
  labelProps,
  required,
  children,
  className,
  space,
  tip,
}: BaseFieldProps) {
  return (
    <Layout.Stack
      className={className}
      space={space}
      data-testid="formik-base-field"
    >
      {label ? (
        <Label
          htmlFor={id}
          required={required}
          {...labelProps}
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            verticalAlign: 'middle',
          }}
        >
          {label}
          {tip && (
            <Tooltip message={tip} placement="top" trigger="hover">
              <Icon
                name="question"
                size="small"
                data-testid={`${id}-tooltip`}
              />
            </Tooltip>
          )}
        </Label>
      ) : null}
      {children}
      {error ? <StyledErrorMessage error={error} /> : null}
    </Layout.Stack>
  )
}

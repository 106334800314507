import { Icon, Layout, Table, Tag, Text } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { isEmpty } from 'lodash'
import styled from 'styled-components'

import type { FacilityDetailsV2 } from 'services/facilities'

import { formatFacilityHoursOfOperationByDay } from './FacilityDetails.helpers'

const StyledTableCell = styled(Table.Cell)`
  padding-block: ${toCSSValue('spacing-4')};
  vertical-align: text-top;
`

function DayCell({ day, disabled }: { day: string; disabled?: boolean }) {
  return (
    <StyledTableCell>
      <Layout.Group gap="spacing-2" align="center">
        <Icon
          name="calendar"
          size="14"
          color={disabled ? 'color-text-disabled' : 'color-text-placeholder'}
        />
        <Text
          variant="body-md"
          color={disabled ? 'color-text-disabled' : 'color-text-primary'}
        >
          {day}
        </Text>
      </Layout.Group>
    </StyledTableCell>
  )
}

type FacilityHoursOfOperationTableProps = Nullable<
  Pick<FacilityDetailsV2, 'hours_of_operation'>
>

export function FacilityHoursOfOperationTable({
  hours_of_operation,
}: FacilityHoursOfOperationTableProps) {
  const hoursOfOperationByDay = formatFacilityHoursOfOperationByDay({
    hours_of_operation,
  })

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeadCell>Day</Table.HeadCell>
          <Table.HeadCell>Operating hours</Table.HeadCell>
          <Table.HeadCell>Schedule policy</Table.HeadCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {Object.keys(hoursOfOperationByDay).map((day) => {
          const dayEntries = hoursOfOperationByDay[day as DayOfWeek]

          if (isEmpty(dayEntries)) {
            return (
              <Table.Row key={day}>
                <DayCell day={day} disabled />
                <StyledTableCell>
                  <Text variant="body-md" color="color-text-disabled">
                    No operating hours
                  </Text>
                </StyledTableCell>
                <StyledTableCell />
              </Table.Row>
            )
          }

          return (
            <Table.Row key={day}>
              <DayCell day={day} />
              <StyledTableCell>
                <Layout.Stack gap="spacing-2">
                  {dayEntries.map(({ key, opens, closes }) => (
                    <Text key={`${key}-operating-hours`} variant="body-md">
                      {opens} — {closes}
                    </Text>
                  ))}
                </Layout.Stack>
              </StyledTableCell>
              <StyledTableCell>
                <Layout.Stack gap="spacing-2" align="flex-start">
                  {dayEntries.map(({ key, appointment_mode }) => (
                    <Tag key={`${key}-schedule-policy`} size="small">
                      {appointment_mode}
                    </Tag>
                  ))}
                </Layout.Stack>
              </StyledTableCell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

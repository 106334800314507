import { createContext, useReducer, useContext } from 'react'
import type { Dispatch } from 'react'

type StateType = {
  step1: any
  step2: any
  nextButtonEnabled: boolean
}

type ActionType = {
  payload: any
  type: string
}

type DispatchType = Dispatch<ActionType>

enum ActionTypes {
  SET_STEP_1_DATA = 'SET_STEP_1_DATA',
  SET_STEP_2_DATA = 'SET_STEP_2_DATA',
  SET_STATUS_NEXT_BUTTON = 'SET_STATUS_NEXT_BUTTON',
}

const initialState: StateType = {
  step1: null,
  step2: null,
  nextButtonEnabled: false,
}

const ShipmentsContext = createContext<any>(initialState)
ShipmentsContext.displayName = 'Shipments'

const ShipmentsProvider = ({ initialValues = initialState, ...props }) => {
  const [state, dispatch] = useReducer(
    (state: StateType, action: ActionType): StateType => {
      switch (action.type) {
        case ActionTypes.SET_STEP_1_DATA:
          return {
            ...state,
            step1: action.payload,
          }
        case ActionTypes.SET_STEP_2_DATA:
          return {
            ...state,
            step2: action.payload,
          }
        case ActionTypes.SET_STATUS_NEXT_BUTTON:
          return {
            ...state,
            nextButtonEnabled: action.payload,
          }
        default:
          return state
      }
    },
    { ...initialValues }
  )

  return <ShipmentsContext.Provider value={[state, dispatch]} {...props} />
}

const useShipmentsContext = () => {
  const context = useContext(ShipmentsContext)

  if (context === undefined) {
    throw new Error(
      `useShipmentsContext must be used within a ShipmentsProvider`
    )
  }
  return context
}

const setStep1Data = (data: any, dispatch: DispatchType) =>
  dispatch({
    type: ActionTypes.SET_STEP_1_DATA,
    payload: data,
  })

const setStep2Data = (data: any, dispatch: DispatchType) =>
  dispatch({
    type: ActionTypes.SET_STEP_2_DATA,
    payload: data,
  })

const setStatusNextButton = (status: boolean, dispatch: DispatchType) =>
  dispatch({
    type: ActionTypes.SET_STATUS_NEXT_BUTTON,
    payload: status,
  })

export {
  ShipmentsProvider,
  useShipmentsContext,
  setStep1Data,
  setStep2Data,
  setStatusNextButton,
}

import { Layout, Text } from '@loadsmart/miranda-react'

import type { FacilityDetailsV2 } from 'services/facilities'

import { formatFacilityAddress } from './FacilityDetails.helpers'

export type FacilityAddressProps = Pick<
  FacilityDetailsV2,
  'address' | 'address_details'
> & {
  inline?: boolean
}

export function FacilityAddress({
  address,
  address_details,
  inline,
}: FacilityAddressProps) {
  if (inline) {
    return (
      <Text data-testid="facility-address">
        {formatFacilityAddress({ address, address_details })}
      </Text>
    )
  }

  return (
    <Layout.Stack gap="none">
      <Text data-testid="facility-address">{address}</Text>
      <Text data-testid="facility-address-details">{address_details}</Text>
    </Layout.Stack>
  )
}

import toArray from 'components/alice/utils/toArray'
import type { RequestForProposal } from 'rfp/rfp.types'
import getFileListForDeletion from 'utils/file'
import httpClient from 'utils/httpClient'
import queryClient from 'utils/queryClient'
import { slugify } from 'utils/strings'

import type { GenericApiSelectOption } from './types'

export const list = async ({
  state,
  limit,
  offset,
  sort,
  name,
  mode,
  frequency,
  rateType,
  bid,
}: {
  state?: string
  limit?: number
  offset?: number
  sort?: string
  name?: string
  mode: string
  frequency: string
  rateType: string
  bid: string
}) => {
  const { data } = await httpClient.get('/proposals/rfp', {
    params: {
      state,
      limit,
      offset,
      sort,
      name,
      mode,
      capacity_time_period: frequency,
      rate_type: rateType,
      with_bids: bid,
    },
  })
  return data
}

export const uploadAttachments = async (
  rfpId: number | string,
  attachments: File[]
) => {
  const filteredAttachments = attachments?.filter((file) => 'size' in file)
  if (filteredAttachments.length === 0) {
    return undefined
  }

  const results = []
  for (const attachment of filteredAttachments) {
    const formData = new FormData()
    formData.append('file', attachment)

    const { data } = await httpClient.post(
      `/proposals/rfp/${rfpId}/attachment`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    results.push(data)
  }

  return results
}

export const deleteAttachments = async (
  rfpId: number | string,
  attachmentsIds: number[]
) => {
  const results = []
  for (const id of attachmentsIds) {
    const { data } = await httpClient.delete(
      `/proposals/rfp/${rfpId}/attachment/${id}`
    )
    results.push(data)
  }

  return results
}

export const retrieve = async (id: number | string) => {
  const { data } = await httpClient.get(`/proposals/rfp/${id}`)
  return data
}

export const partialUpdate = async ({
  id,
  payload,
  attachments,
}: {
  id: number | string
  payload: Partial<RequestForProposal>
  attachments?: { formAttachments: File[]; oldAttachments: Attachment[] }
}) => {
  const { data } = await httpClient.patch(`/proposals/rfp/${id}`, payload)

  if (attachments?.oldAttachments) {
    const attachmentIds = getFileListForDeletion(
      attachments.formAttachments,
      attachments.oldAttachments
    )
    await deleteAttachments(id, attachmentIds)
  }
  if (attachments?.formAttachments) {
    await uploadAttachments(id, attachments.formAttachments)
  }

  await queryClient.refetchQueries({
    queryKey: ['retrieveRFP', String(id)],
    exact: false,
  })

  return data
}

export const remove = async (id: number | string) => {
  const { data } = await httpClient.delete(`/proposals/rfp/${id}`)
  return data
}

export const validateLanesFile = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await httpClient.post(
    '/proposals/rfp/file_validation',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

export const create = async (form: FormData) => {
  const { data } = await httpClient.post('/proposals/rfp', form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

export const duplicate = async (id: number) => {
  const { data } = await httpClient.post(`/proposals/rfp/${id}/duplicate`)
  return data
}

export const sendAwardsByEmail = async (id: number) => {
  const { data } = await httpClient.get(
    `/proposals/rfp_export/${id}/award_pdfs`
  )
  return data
}

export const exportResource = async ({
  id,
  resource,
  format,
}: {
  id: number | string
  resource: 'awards' | 'bids'
  format: 'csv' | 'excel' | 'csv_rows'
}) => {
  const { data, headers, status } = await httpClient.get(
    `/proposals/rfp_export/${id}/${resource}_${format}`,
    {
      responseType: 'arraybuffer',
    }
  )
  return { data, type: headers['content-type'], status }
}

export const exportAwardLettersToEmail = async (rfp_id: number) => {
  const { data } = await httpClient.get(
    `/proposals/rfp_export/${rfp_id}/award_pdfs`
  )
  return data
}
export const exportRFPReport = async ({
  start_period_report,
  end_period_report,
  format,
}: {
  start_period_report: string
  end_period_report: string
  format: string
}) => {
  const { data, headers } = await httpClient.get(
    `/proposals/rfp_export/rfp_report_${format}?start_period_report=${start_period_report}&end_period_report=${end_period_report}`,
    {
      responseType: 'arraybuffer',
    }
  )
  return { data, type: headers['content-type'] }
}
export const downloadSample = async (rfpId?: number) => {
  const rfpIdUrl = rfpId ? `?rfp=${rfpId}` : ''
  const { data, headers } = await httpClient.get(
    `/proposals/rfp/sample${rfpIdUrl}`,
    {
      responseType: 'arraybuffer',
    }
  )
  return { data, type: headers['content-type'] }
}

export const downloadResource = async (rfpReportdata: {
  rfpId: number
  rfpName: string
  type: string
  format: string
}) => {
  const reportName = `${slugify(rfpReportdata.rfpName)}-${rfpReportdata.type}`
  const { data, headers } = await httpClient.get(
    `/proposals/rfp_export/${rfpReportdata.rfpId}/download_report?report_type=${rfpReportdata?.type}&report_format=${rfpReportdata?.format}`,
    {
      responseType: 'arraybuffer',
    }
  )
  return { data, reportName, type: headers['content-type'] }
}

export const summary = async (id: number) => {
  const { data } = await httpClient.get(`/proposals/rfp_summary/${id}`)
  return data
}

export const totalizers = async (id: number | string) => {
  const { data } = await httpClient.get(`/proposals/rfp/${id}/totalizers`)
  return data
}

export const MODES_ENDPOINT = '/proposals/modes'
export const getModes = async () => {
  const { data } = await httpClient.get(MODES_ENDPOINT)
  return data
}

export const getRateTypes = async () => {
  const { data } = await httpClient.get('/proposals/rate_types')
  return data
}

export const getTimePeriods = async () => {
  const { data } = await httpClient.get('/proposals/time_periods')
  return data
}

export const EQUIPMENT_TYPES_ENDPOINT = '/proposals/equipment_types'
export const getEquipmentTypes = async (modeFilter?: string | string[]) => {
  const modes = toArray(modeFilter)
  let qs = ''

  modes.forEach((mode, index) => {
    qs = `${qs}${index > 0 ? '&' : ''}modes=${mode}`
  })

  const { data } = await httpClient.get(`${EQUIPMENT_TYPES_ENDPOINT}?${qs}`)

  return data as GenericApiSelectOption[]
}

export const getEquipmentTypesGroupedByMode = async () => {
  const { data } = await httpClient.get(`/proposals/mode_equipment_types`)
  return data as { [key: string]: GenericApiSelectOption[] }
}

export const getEquipmentSizes = async () => {
  const { data } = await httpClient.get(`/proposals/equipment_sizes`)
  return data as GenericApiSelectOption[]
}

export const getAwardAcceptanceDealines = async () => {
  const { data } = await httpClient.get('/proposals/award_acceptance_deadlines')
  return data
}

export const getCurrencies = async () => {
  const { data } = await httpClient.get('/proposals/currencies')
  return data
}

export const getBidTypes = async () => {
  const { data } = await httpClient.get('/proposals/bid_types')
  return data
}

export const getDistanceTypes = async () => {
  const { data } = await httpClient.get('/proposals/distance_types')
  return data as GenericApiSelectOption[]
}

export const uploadLanes = async ({
  files,
  rfpId,
}: {
  files: File[]
  rfpId: string | number
}) => {
  const formData = new FormData()
  files.forEach((file: File) => formData.append('file', file))
  if (rfpId) {
    formData.append('rfp', `${rfpId}`)
  }
  const { data } = await httpClient.post(
    `/proposals/rfp/${rfpId}/lane/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  await queryClient.refetchQueries({ queryKey: ['retrieveRFP'], exact: false })
  return data
}

export const bulkDeleteLanes = async ({
  rfpId,
  laneIds,
}: {
  rfpId: string | number
  laneIds: Array<string | number>
}) => {
  const { data } = await httpClient.delete(
    `/proposals/rfp/${rfpId}/lane/bulk_delete`,
    {
      data: { lane_ids: laneIds },
    }
  )

  return data
}

import styled, { createGlobalStyle } from 'styled-components'

import { Input } from 'components/Input'

export const GlobalStyle = createGlobalStyle`
  .Toastify__toast-body {
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.backgroundMediumGray};
  padding: 16px;
`

export const FieldsWrapper = styled.div`
  display: grid;
  grid-gap: 24px 16px;
  grid-template-columns: auto 188px;
`

export const ScheduleWrapper = styled.div`
  grid-column-end: span 2;
`

export const ScheduleFields = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto auto auto auto;
`

export const TimeInput = styled(Input)`
  display: block;
`

export const ToastText = styled.p`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  margin: 9px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
`

import { Spinner } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import * as Sentry from '@sentry/react'
import { lazy, Suspense, useContext, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Delighted from 'components/Delighted'
import Page, { LoggedOutPage } from 'components/Page'
import PrivateRoute from 'components/PrivateRoute'
import { useSettings } from 'contexts/settings'
import { ShipmentsProvider } from 'contexts/shipments'
import { SpotQuoteProvider } from 'contexts/spotQuotes'
import { userContext } from 'contexts/user'
import * as ErrorScreens from 'screens/ErrorScreens'
import { theme } from 'styles/theme'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { DATA_INSIGHTS_URL, USER_CATEGORIES, USER_TYPE } from 'utils/constants'
import userUtils from 'utils/user'

import { AppRoutes } from './AppRoutes'

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const ShipperRFPList = lazy(() => import('../rfp/rfp-list'))
const ShipperRFPDetails = lazy(() => import('../rfp/rfp-details'))
const ShipperRFPAdd = lazy(() => import('../rfp/rfp-add'))
const ShipperRFPEdit = lazy(() => import('../rfp/rfp-edit'))
const ShipperSettings = lazy(() => import('../screens/Shipper/Settings'))
const Login = lazy(() => import('../screens/Login'))
const Logout = lazy(() => import('../components/Logout'))
const SignUp = lazy(() => import('../screens/SignUp'))
const SignUpRevamped = lazy(() => import('../screens/SignUpRevamped'))
const EmailVerification = lazy(() => import('../screens/EmailVerification'))
const ForgotPassword = lazy(() => import('../screens/ForgotPassword'))
const ResetPassword = lazy(() => import('../screens/ResetPassword'))
const CompleteRegistration = lazy(
  () => import('../screens/CompleteRegistration')
)
const PendingActivation = lazy(() => import('../screens/PendingActivation'))

const OrdersList = lazy(() => import('../screens/Orders/List'))
const PlanOrders = lazy(() => import('../screens/Orders/Plan'))

const FreightWaves = lazy(() => import('../freightwaves/freightwaves-list'))

const Chat = lazy(() => import('../components/Chat'))

const CarrierManagement = lazy(
  () => import('../screens/Shipper/CarrierManagement')
)
const Lanes = lazy(() => import('../screens/Shipper/Lanes'))
const ShipmentsList = lazy(
  () => import('../screens/Shipper/Shipments/ShipmentList')
)
const AutoTenderRules = lazy(
  () => import('../screens/Shipper/Shipments/AutoTenderRules')
)
const NewShipment = lazy(
  () => import('../screens/Shipper/Shipments/NewShipment')
)
const CreateShipment = lazy(
  () => import('../screens/Shipper/Shipments/create/index')
)
const ShipmentDetails = lazy(
  () => import('../screens/Shipper/Shipments/Details')
)

const ShipmentEditPage = lazy(
  () => import('../screens/Shipper/Shipments/edit/ShipmentEditPage')
)

const ShipmentTracking = lazy(
  () => import('../screens/Shipper/ShipmentTracking')
)
const NewQuote = lazy(() => import('../screens/NewQuote'))
const QuoteList = lazy(() => import('../screens/Quotes/List'))
const QuoteDetails = lazy(() => import('../screens/Quotes/Details'))
const Checkout = lazy(() => import('../screens/Shipper/Checkout'))
const ContractsList = lazy(() => import('../contracts/contracts-list'))
const ContractDetails = lazy(() => import('../contracts/contract-details'))
const NewContract = lazy(() => import('../contracts/contract-add'))
const EditContract = lazy(() => import('../contracts/contract-edit'))
const FacilityManagement = lazy(
  () => import('../screens/Shipper/FacilityManagement')
)
const DataInsightsIframePage = lazy(
  () => import('../screens/Shipper/DataInsights/DataInsightsIframePage')
)
const InsightsPage = lazy(() => import('../screens/Insights/InsightsPage'))
const BulkInvite = lazy(() => import('../screens/Quotes/BulkInvite'))
const BulkQuotesFromShipments = lazy(
  () => import('../screens/Quotes/BulkQuotesFromShipments')
)
const CarrierChat = lazy(
  () => import('../packages/chat/src/components/CarrierChat')
)

export function Router() {
  const { user } = useContext(userContext)
  const { pathname, search } = useLocation()

  useEffect(() => {
    if (user) {
      analytics.setUser(user)
    }
  }, [pathname, user])

  useEffect(() => {
    analytics.track(
      AnalyticsEvent.ShipperGuidePageViews,
      AnalyticsEventTrigger.view,
      { url: pathname }
    )
  }, [pathname])

  const {
    values: [
      facilityManagementEnabled,
      enablePriceMonitor,
      rbtSettingsEnabled,
      zendeskWidgetChangesEnabled,
      ordersPageEnabled,
      planOrdersPageEnabled,
      enableSignupRevamped,
      enableOnePagerShipmentCreate,
      enableInsightsAI,
    ],
  } = useSettings([
    'flags.ENABLE_FACILITY_MANAGEMENT',
    'flags.ENABLE_PRICE_MONITOR',
    'flags.ENABLE_RBT_SETTINGS',
    'flags.ENABLE_ZENDESK_WIDGET_CHANGES',
    'flags.ENABLE_ORDERS_PAGE',
    'flags.ENABLE_PLAN_ORDERS_PAGE',
    'flags.ENABLE_SIGNUP_REVAMPED',
    'flags.ENABLE_ONE_PAGER_SHIPMENT_CREATE',
    'flags.ENABLE_FREIGHT_INSIGHTS_AI',
  ])

  const isPaidUser = user?.user_category === USER_CATEGORIES.PAID_SHIPPER

  /**
   * User will have different home pages based on their userType
   */
  const getHomePage = () => {
    if (userUtils.isUserType([USER_TYPE.MTC])) {
      return <Redirect to="/shipments" />
    }

    return <Redirect to="/quotes/new" />
  }

  const getSignupComponent = () => {
    return enableSignupRevamped ? <SignUpRevamped /> : <SignUp />
  }

  return (
    <Sentry.ErrorBoundary fallback={<ErrorScreens.NetworkError />}>
      <Suspense
        fallback={
          <SpinnerWrapper>
            <Spinner size={50} />
          </SpinnerWrapper>
        }
      >
        {!zendeskWidgetChangesEnabled && userUtils.isLoggedIn && <Chat />}
        {userUtils.isLoggedIn && <CarrierChat />}
        <Switch>
          <Redirect
            from="/:url*(/+)"
            to={`${pathname.slice(0, -1)}${search}`}
          />
          <PrivateRoute
            exact
            path={AppRoutes.RFP}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <Delighted />
              <ShipperRFPList />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.NewRFP}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <ShipperRFPAdd />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.RFPDetails}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <ShipperRFPDetails />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.RFPReportDownload}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <ShipperRFPDetails />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.EditRFP}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <ShipperRFPEdit />
            </Page>
          </PrivateRoute>
          <PrivateRoute path={AppRoutes.ShipperSettings}>
            <Page padding={getToken('space-none')}>
              <ShipperSettings />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Carriers}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <Delighted />
              <CarrierManagement />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Lanes}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <Delighted />
              <Lanes />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.ShipperReports}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page padding="0">
              <DataInsightsIframePage
                url={`${DATA_INSIGHTS_URL}/shipper-guide/?redirectTo=report`}
                title="Reports"
              />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Insights}
            enabled={enableInsightsAI && userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page padding="0">
              <InsightsPage />
            </Page>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={AppRoutes.NewQuote}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page color={theme.colors.whiteBroken} padding="0">
              <NewQuote />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.QuoteDetails}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page color={theme.colors.whiteBroken} padding="0">
              <QuoteDetails />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Checkout}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page color={theme.colors.neutralLight} padding="2em">
              <Checkout />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Quotes}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page>
              <Delighted />
              <SpotQuoteProvider>
                <QuoteList />
              </SpotQuoteProvider>
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.BulkInvite}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page color={theme.colors.whiteBroken} padding="0">
              <BulkInvite />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.BulkQuotesFromShipments}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page>
              <BulkQuotesFromShipments />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Shipments}
            enabled={userUtils.isUserType([
              USER_TYPE.SG,
              USER_TYPE.FBM,
              USER_TYPE.MTC,
            ])}
          >
            <ShipmentsProvider>
              <Page padding="0">
                <Delighted />
                <ShipmentsList />
              </Page>
            </ShipmentsProvider>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.AutoTenderRules}
            enabled={
              rbtSettingsEnabled &&
              isPaidUser &&
              userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])
            }
          >
            <Page padding="0">
              <AutoTenderRules />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.NewShipment}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <ShipmentsProvider>
              <Page>
                <NewShipment />
              </Page>
            </ShipmentsProvider>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.CreateShipment}
            enabled={enableOnePagerShipmentCreate}
          >
            <Page>
              <CreateShipment />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.EditShipment}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page color={toCSSValue('color-background-secondary')}>
              <ShipmentEditPage />
            </Page>
          </PrivateRoute>

          <PrivateRoute
            path={AppRoutes.ShipmentDetails}
            enabled={userUtils.isUserType([
              USER_TYPE.SG,
              USER_TYPE.FBM,
              USER_TYPE.MTC,
            ])}
          >
            <ShipmentsProvider>
              <Page color={toCSSValue('color-background-secondary')}>
                <ShipmentDetails />
              </Page>
            </ShipmentsProvider>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={AppRoutes.FacilityManagement}
            enabled={
              facilityManagementEnabled &&
              userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])
            }
          >
            <Page>
              <FacilityManagement />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Contracts}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <ContractsList />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.NewContract}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <NewContract />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.EditContract}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <EditContract />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.ContractDetails}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <ContractDetails />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            path={AppRoutes.OrdersList}
            enabled={
              ordersPageEnabled &&
              userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])
            }
          >
            <Page padding="0">
              <OrdersList />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            path={AppRoutes.PlanOrders}
            enabled={planOrdersPageEnabled}
          >
            <Page>
              <PlanOrders />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.PriceMonitor}
            enabled={Boolean(
              enablePriceMonitor && userUtils.isUserType([USER_TYPE.SG])
            )}
          >
            <Page padding="0">
              <DataInsightsIframePage
                url={`${DATA_INSIGHTS_URL}/shipper-guide/?redirectTo=monitor`}
                title="Price Monitor"
              />
            </Page>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={AppRoutes.FreightWaves}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <FreightWaves />
            </Page>
          </PrivateRoute>

          <Route exact path="/shipment-tracking/:shipmentId">
            <ShipmentTracking />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/fbm-login">
            <Login />
          </Route>
          <Route exact path="/signup">
            {getSignupComponent()}
          </Route>
          <Route exact path="/fbm-signup">
            <SignUp />
          </Route>
          <Route exact path="/email_verification/:token">
            <LoggedOutPage color={theme.colors.background}>
              <EmailVerification />
            </LoggedOutPage>
          </Route>
          <Route exact path="/email_verification">
            <LoggedOutPage color={theme.colors.background}>
              <EmailVerification />
            </LoggedOutPage>
          </Route>
          <Route exact path="/forgot_password">
            <LoggedOutPage color={theme.colors.background}>
              <ForgotPassword />
            </LoggedOutPage>
          </Route>
          <Route exact path="/reset_password/:token">
            <LoggedOutPage color={theme.colors.background}>
              <ResetPassword />
            </LoggedOutPage>
          </Route>
          <Route exact path="/complete_registration/:token">
            <LoggedOutPage color={theme.colors.background}>
              <CompleteRegistration />
            </LoggedOutPage>
          </Route>
          <Route exact path="/pending_activation">
            <PendingActivation />
          </Route>
          <Route exact path="/logout">
            <Logout />
          </Route>
          <Route exact path="/">
            {getHomePage()}
          </Route>
          <Route path="*">
            <ErrorScreens.NotFound />
          </Route>
        </Switch>
      </Suspense>
    </Sentry.ErrorBoundary>
  )
}

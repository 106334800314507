import type { ConversationParticipant } from 'packages/chat/src/types'
import type { Rate } from 'screens/NewQuote/types'

export const enum ChatSource {
  Direct = 'direct',
  Group = 'group',
  GroupUpdate = 'group_update',
  RateItemDropdown = 'rate_item_dropdown',
  RateItemIcon = 'rate_item_icon',
  CarrierNotesDrawer = 'carrier_notes_drawer',
  CounterOfferDrawer = 'counter_offer_drawer',
  ShipmentDetailsActions = 'shipment_details_actions',
  CarrierCardActions = 'carrier_card_actions',
  AskCarrierForUpdatesModal = 'ask_carrier_for_updates_modal',
  AskCarrierForDocumentsModal = 'ask_carrier_for_documents_modal',
  CarrierAppointmentScheduleModal = 'carrier_appointment_schedule_modal',
}

export type ChatNewContactData = {
  canStartChat: boolean
}

export type ChatCounterOfferData = {
  rate: Rate
  quoteUuid: string
}

export type ChatWindowEventData = {
  conversationId: string
  subject: string
  participants?: Array<ConversationParticipant>
  type: 'direct' | 'group'
  source: ChatSource
}

export type ChatCarrierNotesData = {
  rate: Rate
  quoteUuid: string
}

import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import type { QueryObserverOptions } from 'react-query'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import Talk from 'talkjs'

import { createSession, openChatWindow, updateChatGroup } from '../chat'
import type { ChatSource } from '../chatEvents.type'
import { mapApiToChats, mapNewGroupChatToApi } from '../mappers/chatMapper'
import { searchChats } from '../presenters/chatPresenter'
import {
  createConversation,
  getChatsList,
  getUserContactInfo,
} from '../services'
import type {
  Chat,
  ChatContactInfoResponse,
  ChatContext,
  Contact,
  ConversationParticipant,
  RawChat,
} from '../types'

type ChatListQueryOptions = QueryObserverOptions<RawChat[], unknown, Chat[]>

export function useChatList(options: ChatListQueryOptions = {}) {
  return useQuery<RawChat[], unknown, Chat[]>(['ChatList'], getChatsList, {
    refetchOnWindowFocus: false,
    select: (data) => mapApiToChats(data),
    retry: false,
    queryKey: ['ChatList'],
    queryFn: getChatsList,
    ...options,
  })
}

export function useSearchChats(options: ChatListQueryOptions = {}) {
  const { data, isLoading, isRefetching, ...rest } = useChatList(options)
  const [query, setQuery] = useState<string>('')
  const [filteredData, setFilteredData] = useState<Chat[]>([])

  useEffect(() => {
    if (data && !isLoading && filteredData.length === 0 && !query) {
      setFilteredData(data)
    }
  }, [data, filteredData.length, isLoading, query])

  useEffect(() => {
    const searchQuery = query.trim().toLowerCase()
    const filteredResults = searchChats(searchQuery, data)
    setFilteredData(filteredResults)
  }, [data, query])

  return {
    ...rest,
    isLoading: isLoading || isRefetching,
    data: filteredData,
    setQuery,
  }
}

export function useChatSession() {
  const { userData } = useChatUserData()
  const [session, setSession] = useState<Talk.Session>()
  const [unreadConversations, setUnreadConversations] = useState<
    Array<Talk.UnreadConversation>
  >([])
  const unreadCount = unreadConversations.length

  useEffect(() => {
    if (!userData) {
      return
    }

    const initializeSession = async () => {
      await Talk.ready

      const talkSession = createSession(userData)

      talkSession?.unreads.onChange((conversations) => {
        setUnreadConversations(conversations)
      })

      setSession(talkSession)
    }

    initializeSession()
  }, [userData])

  return { session, unreadCount, unreadConversations }
}

export type CreateGroupChatProps = {
  contacts: Array<Omit<Contact, 'name' | 'email'>>
  subject: string
  context?: ChatContext
  source: ChatSource
}

export function useCreateChatGroup() {
  const { userData: cachedUserInfo, refetch } = useChatUserData({
    enabled: false,
  })

  const { mutateAsync } = useMutation({
    mutationFn: async (params: CreateGroupChatProps) => {
      const userData =
        cachedUserInfo ??
        (
          await refetch({
            throwOnError: true,
          })
        ).data! // since we throwOnError, we can assume data is defined

      const payload = mapNewGroupChatToApi(params, userData)

      return createConversation(payload)
    },
    onSuccess: (data, variables: CreateGroupChatProps) => {
      const { uuid, subject, participants, type } = data

      openChatWindow({
        conversationId: uuid,
        subject,
        participants,
        type,
        source: variables.source,
      })
    },
    onError: (error) => {
      toast.error(
        'Could not initialize a group conversation, please contact an admin',
        {
          position: 'top-left',
        }
      )

      const newError = new Error('Failed to create group chat', {
        cause: error,
      })
      Sentry.captureException(newError)
    },
  })

  return { createChatGroup: mutateAsync }
}

type UpdateChatProps = {
  conversationId: string
  subject?: string
  participants?: ConversationParticipant[]
}

export function useUpdateChat() {
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    ({ conversationId, subject, participants }: UpdateChatProps) =>
      updateChatGroup(conversationId, subject, participants)
  )

  return {
    updateChatGroup: mutateAsync,
    isLoading,
    isSuccess,
    isError,
  }
}

export function useChatUserData(
  options: QueryObserverOptions<
    ChatContactInfoResponse,
    Error,
    ChatContactInfoResponse,
    ChatContactInfoResponse,
    string[]
  > = {}
) {
  const { isLoading, isSuccess, isError, data, refetch } = useQuery({
    ...options,
    staleTime: Infinity,
    queryKey: ['chatUserData'],
    queryFn: ({ signal }) => getUserContactInfo({ signal }),
  })

  return {
    isLoading,
    isSuccess,
    isError,
    userData: data,
    refetch,
  }
}

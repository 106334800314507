import { useSettings } from 'contexts/settings'
import { useShipperFacilitiesMigratedSetting } from 'hooks/useShipperFacilitiesMigratedSetting'

export function useFacilityDetailsV2Flag() {
  const {
    values: [facilitiesSummaryV2Enabled],
    isLoading: isFlagLoading,
  } = useSettings(['flags.ENABLE_FACILITY_SUMMARY_V2'])
  const isFlagEnabled = Boolean(facilitiesSummaryV2Enabled)
  const { isShipperSettingsLoading, isShipperMigrated } =
    useShipperFacilitiesMigratedSetting({ enabled: isFlagEnabled })

  return {
    isFacilityDetailsV2Loading: isFlagLoading || isShipperSettingsLoading,
    isFacilityDetailsV2Enabled: isShipperMigrated && isFlagEnabled,
  }
}

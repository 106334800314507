/**
 * @param {string} [containerQuery=main] - A selector query to get the container in which we want to scroll to top. If not provided, the scroll will be done on the main container.
 */
export function scrollToTop(containerQuery = 'main') {
  document.querySelector(containerQuery)?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

import { Drawer, Layout, Field, Text } from '@loadsmart/miranda-react'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import SchedulingContacts from 'components/SchedulingContacts'
import { useFacilityDetails } from 'hooks/useQuery'
import { createContact, deleteContact } from 'services/shipments'
import { phoneFormatter } from 'utils/phone'

type Contact = {
  contact_uuid?: string
  contact_name?: string
  contact_phone: string
  contact_email?: string
  contact_ext?: string
}

type InitialValues = {
  contacts: Array<Contact>
}

export interface ContactsProps {
  isOpen: boolean
  handleCloseDrawer: () => void
  facilityUUID: string
  callback: (contact: any) => void | Promise<undefined>
  onClosed: () => void
}

export const ContactsDrawer = ({
  isOpen,
  handleCloseDrawer,
  facilityUUID,
  onClosed,
  callback,
}: ContactsProps) => {
  const initialValues: InitialValues = {
    contacts: [],
  }

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  })

  const fetchFacilityDetailsEnabled = Boolean(facilityUUID && isOpen)

  const { data: facility, refetch: refetchFacilityDetails } =
    useFacilityDetails(facilityUUID, {
      enabled: fetchFacilityDetailsEnabled,
    })

  const { contacts } = facility ?? {}

  useEffect(() => {
    if (contacts && isOpen) {
      const adaptedContacts = contacts.map((contact: any) => ({
        contact_uuid: contact?.uuid,
        contact_name: contact.name,
        contact_phone: contact.phone_number,
        contact_ext: contact.ext || '',
        contact_email: contact.email,
      }))
      setFieldValue('contacts', adaptedContacts)
    }
  }, [contacts, isOpen, setFieldValue])

  const { mutate: mutateContacts } = useMutation({
    mutationFn: createContact,
    onSuccess(data) {
      const { uuid, name, phone_number, email } = data

      const contact = {
        uuid,
        name,
        phone_number,
        email,
      }

      callback(contact)

      refetchFacilityDetails()
      toast.info('Contact added')
    },
    onError() {
      toast.error('Unable to add the contact. Please try again.')
    },
  })

  const { mutate: removeContact } = useMutation({
    mutationFn: deleteContact,
    onSuccess() {
      refetchFacilityDetails()
      toast.info('Contact deleted')
    },
    onError() {
      toast.error('Unable to delete contact. Please try again.')
    },
  })

  const onSaveContact = (contact: Contact) => {
    const newContact = {
      name: contact.contact_name,
      phone_number: phoneFormatter(contact.contact_phone),
      ext: contact?.contact_ext,
      email: contact.contact_email || null,
    }
    mutateContacts({ facilityUUID, contact: newContact })
  }

  const onDeleteContact = (contactUUID: string) => {
    removeContact({ facilityUUID, contactUUID })
  }

  return createPortal(
    <Drawer
      open={isOpen}
      size="medium"
      onClose={handleCloseDrawer}
      data-testid="contacts-drawer"
      onClosed={onClosed}
    >
      <Drawer.Header role="heading">
        Contacts
        <Drawer.Close aria-label="Close contacts drawer" />
      </Drawer.Header>

      <Drawer.Body>
        <Layout.Stack gap="spacing-6">
          <Field>
            <Field.Label>Facility name</Field.Label>
            <Text variant="heading-sm-bold" data-testid="facility-name">
              {facility?.company_name}
            </Text>
          </Field>

          <SchedulingContacts
            contacts={values.contacts}
            setFieldValue={setFieldValue}
            onSaveContact={onSaveContact}
            onDeleteContact={onDeleteContact}
          />
        </Layout.Stack>
      </Drawer.Body>
      <Drawer.Actions>
        <Drawer.ActionTertiary onClick={handleCloseDrawer}>
          Close
        </Drawer.ActionTertiary>
      </Drawer.Actions>
    </Drawer>,
    document.body,
    'contacts-drawer-root'
  )
}

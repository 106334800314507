import { SideNavigation } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'

import { AppRoutes } from 'router/AppRoutes'
import { USER_TYPE } from 'utils/constants'
import userWrapper from 'utils/user'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

export const PlanMenu = () => {
  const history = useHistory()

  if (userWrapper.isUserType([USER_TYPE.SG])) {
    return (
      <SideNavigation.Menu label="Plan">
        <SideNavigation.Menu.Item
          active={isActivePath(AppRoutes.OrdersList)}
          label="Orders"
          url={AppRoutes.OrdersList}
          onClick={(event) => {
            event.preventDefault()
            navbarLinkClicked('Orders', AppRoutes.OrdersList, history)
          }}
        />
      </SideNavigation.Menu>
    )
  }

  return null
}

import { Select } from '@loadsmart/loadsmart-ui'
import type { SelectProps } from '@loadsmart/loadsmart-ui'
import {
  Card,
  Field,
  Layout,
  Tag,
  Text,
  TextField,
} from '@loadsmart/miranda-react'
import { get, isEmpty } from 'lodash'
import { useEffect, useState } from 'react'

import { useTransientField } from '_shared_/hooks/useTransientField'
import { resolveCountry } from 'utils/states'
import { plural } from 'utils/strings'
import { getTransientErrorsCount } from 'utils/transient'

import { AddressSearch } from './AddressSearch'
import type { TransientFacilityLocation } from './Facility.types'
import {
  COUNTRIES_OPTIONS,
  getCountryOption,
  createTransientFacilityLocation,
  getStateOption,
  getStatesFor,
} from './LocationSection.helpers'

export type LocationSectionProps = {
  collapsible?: boolean
  initialCollapsed?: boolean
  location?: TransientFacilityLocation
  onChange?: (location: TransientFacilityLocation) => void
  includeMexOption?: boolean
}

function CountrySelect(
  props: Omit<Partial<SelectProps>, 'value'> &
    Pick<LocationSectionProps, 'includeMexOption'> & { value?: string | null }
) {
  const { value, includeMexOption, ...rest } = props

  /**
   * the `hideClear` is to save space rather than preventing the user
   * from actually clearing the value.
   */
  return (
    <Select
      name="country"
      options={
        includeMexOption
          ? COUNTRIES_OPTIONS
          : COUNTRIES_OPTIONS.filter((option) => option.value !== 'MEX')
      }
      {...rest}
      value={getCountryOption(value)}
      hideClear
    />
  )
}

function StateSelect(
  props: Omit<Partial<SelectProps>, 'value'> & {
    country?: string | null
    value?: string | null
  }
) {
  const { country, value, ...rest } = props
  const options = getStatesFor(country)

  /**
   * the `hideClear` is to save space rather than preventing the user
   * from actually clearing the value.
   */
  return (
    <Select
      name="state"
      options={options}
      {...rest}
      value={getStateOption(country, value)}
      hideClear
    />
  )
}

function getSelectInputProps() {
  return {
    'aria-labelledby': 'label-for-location-address',
    maxLength: 200,
  }
}

export function LocationSection(props: LocationSectionProps) {
  const {
    collapsible,
    initialCollapsed,
    location = createTransientFacilityLocation(),
    onChange,
    includeMexOption,
  } = props

  const [showAddressSearch, setShowAddressSearch] = useState(
    isEmpty(location.address)
  )
  const { getFieldProps, getHintProps } = useTransientField(
    location,
    'location'
  )
  const errorsCount = getTransientErrorsCount(location)

  useEffect(() => {
    setShowAddressSearch(isEmpty(location.address))
  }, [location.address])

  return (
    <Card
      collapsible={collapsible}
      initialCollapsed={initialCollapsed}
      data-testid="location-section"
    >
      <Card.Title role="heading" aria-level={2}>
        <Layout.Group gap="spacing-2" align="center">
          <Text variant="heading-sm-bold" color="color-text-secondary">
            Location
          </Text>
          {errorsCount > 0 && (
            <Tag variant="danger" size="small">
              &nbsp;{plural(`${errorsCount} error`, errorsCount)}
            </Tag>
          )}
        </Layout.Group>
      </Card.Title>
      <Card.Subtitle role="heading" aria-level={3}>
        Set facility name and location details
      </Card.Subtitle>
      <Card.Divider />
      <Card.Body>
        <Layout.Container>
          <Layout.Row>
            <Layout.Column sm="4">
              <Field required {...getFieldProps('name')}>
                <Field.Label id="label-for-facility-name">
                  Facility name
                </Field.Label>
                <TextField
                  aria-labelledby="label-for-facility-name"
                  placeholder="Enter facility name"
                  value={location.name ?? ''}
                  onInput={(event) => {
                    onChange?.({
                      ...location,
                      name: get(event, 'target.value', ''),
                    })
                  }}
                />
                <Field.Hint {...getHintProps('name')} />
              </Field>
            </Layout.Column>
          </Layout.Row>

          <Layout.Row>
            <Layout.Column sm="4">
              <Field required {...getFieldProps('address')}>
                <Field.Label id="label-for-location-address">
                  Address
                </Field.Label>
                {showAddressSearch ? (
                  <AddressSearch
                    placeholder="Search facility address"
                    getInputProps={getSelectInputProps}
                    onChange={(event) => {
                      setShowAddressSearch(false)
                      onChange?.({
                        ...location,
                        address: get(event, 'target.value.address', ''),
                        city: get(event, 'target.value.city', ''),
                        country: resolveCountry(
                          get(event, 'target.value.country')
                        ),
                        state: get(event, 'target.value.state', ''),
                        zipcode: get(event, 'target.value.zipcode', ''),
                      })
                    }}
                    onCreate={(suggestion) => {
                      setShowAddressSearch(false)
                      onChange?.({
                        ...location,
                        address: suggestion,
                      })
                    }}
                  />
                ) : (
                  <TextField
                    aria-labelledby="label-for-location-address"
                    placeholder="Enter line 1"
                    value={location.address ?? ''}
                    clearable
                    onClear={() => {
                      setShowAddressSearch(true)
                    }}
                    onInput={(event) => {
                      onChange?.({
                        ...location,
                        address: get(event, 'target.value', ''),
                      })
                    }}
                  />
                )}
                <Field.Hint {...getHintProps('address')} />
              </Field>
            </Layout.Column>
          </Layout.Row>

          <Layout.Row>
            <Layout.Column sm="4">
              <Field>
                <TextField
                  aria-label="Address line 2"
                  placeholder="Enter line 2 (optional)"
                  value={location.address_details ?? ''}
                  onInput={(event) => {
                    onChange?.({
                      ...location,
                      address_details: get(event, 'target.value', ''),
                    })
                  }}
                />
                <Field.Hint>
                  Building, floor, unit, office, terminal, dock, etc.
                </Field.Hint>
              </Field>
            </Layout.Column>
          </Layout.Row>

          <Layout.Row>
            <Layout.Column sm="2">
              <Field required {...getFieldProps('city')}>
                <Field.Label id="label-for-location-city">City</Field.Label>
                <TextField
                  aria-labelledby="label-for-location-city"
                  placeholder="Enter city"
                  value={location.city ?? ''}
                  onInput={(event) => {
                    onChange?.({
                      ...location,
                      city: get(event, 'target.value', ''),
                    })
                  }}
                />
                <Field.Hint {...getHintProps('city')} />
              </Field>
            </Layout.Column>

            <Layout.Column sm="2">
              <Field required {...getFieldProps('zipcode')}>
                <Field.Label id="label-for-location-zipcode">
                  Zip code
                </Field.Label>
                <TextField
                  aria-labelledby="label-for-location-zipcode"
                  placeholder="Enter zip code"
                  value={location.zipcode ?? ''}
                  onInput={(event) => {
                    onChange?.({
                      ...location,
                      zipcode: get(event, 'target.value', ''),
                    })
                  }}
                />
                <Field.Hint {...getHintProps('zipcode')} />
              </Field>
            </Layout.Column>
          </Layout.Row>

          <Layout.Row>
            <Layout.Column sm="2">
              <Field required {...getFieldProps('country')}>
                <Field.Label>Country</Field.Label>

                <CountrySelect
                  placeholder="Select country"
                  value={location.country}
                  onChange={(event) => {
                    onChange?.({
                      ...location,
                      country: get(event, 'target.value.value', null),
                    })
                  }}
                  includeMexOption={includeMexOption}
                />
                <Field.Hint {...getHintProps('country')} />
              </Field>
            </Layout.Column>

            <Layout.Column sm="2">
              <Field required {...getFieldProps('state')}>
                <Field.Label>State</Field.Label>

                <StateSelect
                  placeholder="Select state"
                  country={location.country}
                  value={location.state}
                  onChange={(event) => {
                    onChange?.({
                      ...location,
                      state: get(event, 'target.value.value', null),
                    })
                  }}
                />
                <Field.Hint {...getHintProps('state')} />
              </Field>
            </Layout.Column>
          </Layout.Row>
        </Layout.Container>
      </Card.Body>
    </Card>
  )
}

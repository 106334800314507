import type { TextFieldProps } from '@loadsmart/loadsmart-ui'
import { TextField } from '@loadsmart/loadsmart-ui'
import type { Ref } from 'react'
import type { MaskedInputProps } from 'react-text-mask'
import MaskedInput from 'react-text-mask'

const PHONE_NUMBER_WITHOUT_COUNTRY_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const PHONE_WITH_COUNTRY_MASK = [
  '+',
  '1',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

type PhoneProps = Omit<TextFieldProps, 'status'> &
  Partial<MaskedInputProps> & {
    status?: 'danger' | 'success' | 'warning' | 'info'
    error?: boolean
  }

export default function Phone({
  mask = PHONE_NUMBER_WITHOUT_COUNTRY_MASK,
  ...otherProps
}: PhoneProps) {
  return (
    <MaskedInput
      mask={mask}
      {...otherProps}
      render={(ref, props) => (
        <TextField ref={ref as Ref<HTMLInputElement>} {...props} />
      )}
    />
  )
}

import { Text } from '@loadsmart/miranda-react'

import type { FacilityDetailsV2 } from 'services/facilities'

import { formatFacilityLocation } from './FacilityDetails.helpers'

export function FacilityLocation({
  city,
  state,
  zipcode,
}: Pick<FacilityDetailsV2, 'city' | 'state' | 'zipcode'>) {
  return (
    <Text
      variant="body-md"
      color="color-text-primary"
      data-testid="facility-location"
    >
      {formatFacilityLocation({ city, state, zipcode })}
    </Text>
  )
}

import { useQuery } from 'react-query'
import type { QueryObserverOptions } from 'react-query'

import { get as getShipperSettings } from 'services/shipperSettings'

export function useShipperFacilitiesMigratedSetting(
  options?: QueryObserverOptions<ShipperSettings>
) {
  const { data: shipperSettings, isLoading: isShipperSettingsLoading } =
    useQuery<ShipperSettings>({
      queryKey: ['shipperSettings'],
      queryFn: () => getShipperSettings(),
      refetchOnWindowFocus: false,
      retry: 3,
      ...options,
    })
  const isShipperMigrated = Boolean(
    shipperSettings?.magazzino_decoupling_migration_complete
  )

  return {
    isShipperSettingsLoading,
    isShipperMigrated,
  }
}

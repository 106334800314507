function assert(value: boolean, message?: string): asserts value
function assert<T>(
  value: T | null | undefined,
  message?: string
): asserts value is T
function assert(value: unknown, message?: string): void {
  if (value === false || value === null || value === undefined) {
    throw new Error(message ? message : 'Assertion failed')
  }
}

export default assert

import type {
  LabelProps,
  SelectProps as MSelectProps,
} from '@loadsmart/loadsmart-ui'
import { Select as MSelect } from '@loadsmart/loadsmart-ui'
import type { Spacing } from '@loadsmart/loadsmart-ui/dist/components/Layout/Layout.types'
import type {
  GenericOption,
  Option,
} from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'

import { BaseField } from './BaseField'
import { CustomFormikField } from './CustomFormikField'

export interface SelectProps
  extends Omit<MSelectProps, 'name' | 'value' | 'form'> {
  label?: string
  labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  name: string
  required?: boolean
  space?: Spacing
  value?: Option | Option[] | null
}

const getOptionByValue = (
  value: string,
  options: GenericOption[] | undefined
): Option => {
  const defaultOption = { value, label: value } as Option

  if (!options) {
    return defaultOption
  }

  const option = options.find((item) => item.value === value)

  return (option as Option) ?? defaultOption
}

const getInitialOption = (
  initialValue?: string | string[],
  options?: GenericOption[]
): Option | Option[] | undefined => {
  if (!initialValue) {
    return
  }

  if (Array.isArray(initialValue)) {
    return initialValue.map((item) => getOptionByValue(item, options))
  }

  return getOptionByValue(initialValue, options)
}

export function Select({ children, ...props }: SelectProps) {
  return (
    <CustomFormikField {...props}>
      {({
        field: {
          onBlur: fieldOnBlur,
          onChange: _,
          value: fieldValue,
          ...field
        },
        form,
        baseFieldProps,
        baseProps,
      }) => {
        const { setFieldTouched, setFieldValue } = form

        const mappedProps = {
          value: getInitialOption(fieldValue, props.options),
          onBlur:
            props.onBlur ??
            (() => {
              return fieldOnBlur(field.name)
            }),
          onChange: ({
            target: { value: selectedValue },
          }: EventLike<GenericOption | GenericOption[] | null>) => {
            if (!selectedValue) {
              setFieldValue(field.name, props.multiple ? [] : '')
              setFieldTouched(field.name, true, false)
              return
            }

            let fieldValue: GenericOption['value'] | GenericOption['value'][]
            if (Array.isArray(selectedValue)) {
              fieldValue = selectedValue.map((item) => item.value)
            } else {
              fieldValue = selectedValue.value
            }

            setFieldValue(field.name, fieldValue)
            setFieldTouched(field.name, true, false)
          },
          ...(children && { children }),
          ...field,
          ...baseProps,
        }

        return (
          <BaseField {...baseFieldProps}>
            <MSelect {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}

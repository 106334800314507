import styled from 'styled-components'

const Text = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-top: 4px !important;
  text-align: left;
  text-transform: none;
`

export default function Error({ error }: { error?: string }) {
  if (!error) {
    return null
  }
  return <Text role="alert">{error}</Text>
}
